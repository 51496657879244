import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { h3Style } from '@ams-package/theme';

import icon from './ic_chevron.png';
import iconUp from './ic_chevron_up.png';

const blockName = 'offer-information';

export const ButtonsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'buttons-wrapper'),
})`
  display: flex;
  gap: 10px;
  button {
    padding: 8px 20px;
  }
`;

export const ImgWrapper = styled.h1.attrs({
  className: classNameWithPrefix(blockName, 'img-wrapper'),
})`
  display: flex;
  padding: 10px 0;
  background-color: var(--black);
  justify-content: center;
  width: 100%;
  align-content: center;
  align-items: center;
  gap: 10px;
`;

export const MarkdownWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'markdown-wrapper'),
})`
  table {
    margin: 20px 0;
    border-collapse: collapse;
    svg,
    img {
      color: var(--primary-color);
    }
  }
  tr {
    background: var(--dark-grey);
    padding: 12px 24px;
  }

  th {
    padding: 12px 24px;
    border-top: none;
    font-size: var(--body-2r-font-size);
    font-family: var(--body-2r-font-family);
    font-weight: var(--body-2r-font-weight);
    line-height: var(--body-2r-line-height);
  }
  td {
    background: var(--grid-background);
    padding: 12px 24px;
    border-top: 1px solid var(--dark-grey);
  }

  th:first-of-type {
    border-top-left-radius: 12px;
  }
  th:last-of-type {
    border-top-right-radius: 12px;
  }
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 12px;
  }
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 12px;
  }
  details {
    border-bottom: 1px solid var(--dark-grey);
    color: var(--popover-text-color);
    padding: 20px 0;
  }
  details > summary {
    ${h3Style}
    color: var(--base-white);
    position: relative;
    list-style: none;
  }
  details > summary::marker {
    display: none;
  }
  details > summary::after {
    padding-left: 12px;
    content: url(${icon});
  }
  details[open] > summary::after {
    padding-left: 12px;
    content: url(${iconUp});
  }
  details[open] > summary {
    padding-bottom: 20px;
  }
  details > ul > li {
    list-style: disc inside;
    padding-top: 10px;
  }
`;
