import wretch from 'wretch';

import {
  IResetPasswordModel,
  ISignInModel,
  ISignInResponse,
  ISignUpModel,
  IRestorePasswordModel,
} from '../types';

export const apiSignIn = async (model: ISignInModel) =>
  wretch(`/api/token/by-email`).post(model).json<ISignInResponse>();

export const apiResetPassword = async (
  model: IResetPasswordModel
): Promise<void> => wretch(`/api/reset-password/by-email`).post(model).res();

export const apiRestorePassword = async (
  model: IRestorePasswordModel
): Promise<void> => wretch(`/api/confirm-reset`).post(model).res();

export const apiSignUp = async (model: ISignUpModel) =>
  wretch(`/api/register/external`).post(model).json<ISignInResponse>();

export const apiValidateRegData = async (model: ISignInModel) =>
  wretch(`/api/register/validate`).post(model).res();
