import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

import {
  getDictionarySelect,
  IPaymentMethodsModel,
} from '@ams-package/dictionaries';
import { getDateRangeString } from '@ams-package/utils';
import { getLimits, getUser } from '@ams-package/app';

import { STORE_NAME } from './store';
import { IRegularPayoutState } from './types';

export const regularPayoutSelector = (state): IRegularPayoutState =>
  state[STORE_NAME];

export const isReadySelector = (state) => regularPayoutSelector(state).isReady;

export const regularPaymentMethodSelector = createSelector(
  getDictionarySelect('paymentMethods'),
  (paymentMethods?: IPaymentMethodsModel[]): IPaymentMethodsModel | undefined =>
    paymentMethods?.find((method) => method.isRegular)
);

export const regularPaymentMethodIdSelector = createSelector(
  regularPaymentMethodSelector,
  (paymentMethod: IPaymentMethodsModel | undefined): number | undefined =>
    paymentMethod?.id
);

export const selectedPayoutPaymentSelector = createSelector(
  regularPaymentMethodSelector,
  getDictionarySelect('payoutPaymentMethods'),
  (selectedMethod, paymentMethods) =>
    paymentMethods.find((method) => method.name === selectedMethod?.name)
);

export const payoutDetailsSelector = (state) =>
  regularPayoutSelector(state).payoutDetails;

export const payoutDateSelector = createSelector(
  payoutDetailsSelector,
  (payoutDetails) => {
    const { minUpcomingPayoutDate, maxUpcomingPayoutDate } =
      payoutDetails ?? {};

    if (!minUpcomingPayoutDate || !maxUpcomingPayoutDate) return '';

    return getDateRangeString(
      parseISO(minUpcomingPayoutDate),
      parseISO(maxUpcomingPayoutDate)
    );
  }
);

export const regularPayoutMinBalanceLimitSelector = (state) =>
  getLimits(state)?.regularPayoutRequestMinBalanceLimit ?? 0;

export const regularPayoutsViewSelector = createSelector(
  isReadySelector,
  payoutDateSelector,
  regularPayoutMinBalanceLimitSelector,
  getUser,
  selectedPayoutPaymentSelector,
  (isReady, payoutDate, minBalance, user, selectedPayment) => ({
    isReady,
    payoutDate,
    minBalance,
    availableWithdrawal:
      user?.balance &&
      user.balance >= minBalance &&
      user.status === 'Active' &&
      isReady,
    selectedPayment,
  })
);
