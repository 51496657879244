import React from 'react';
import { IconProps } from 'iconsax-react';

import { colors } from '@ams-package/theme';

import {
  IconWrapper,
  InfoWrapper,
  StatisticBlockWrapper,
  StatisticValue,
} from './styled';
import { Percent, PercentType } from './Percent';

export type StatisticBlockPropsType = {
  Icon: React.FC<IconProps>;
  title: string;
  value: number | string;
  percent: PercentType;
};

export const StatisticBlock: React.FC<StatisticBlockPropsType> = ({
  title,
  Icon,
  value,
  percent,
}) => (
  <StatisticBlockWrapper>
    <IconWrapper>
      <Icon color={colors.PRIMARY_COLOR} />
    </IconWrapper>
    <InfoWrapper>
      {title}
      <StatisticValue>
        {value}
        <Percent {...percent} />
      </StatisticValue>
    </InfoWrapper>
  </StatisticBlockWrapper>
);
