import React from 'react';

import { PathNames, IRouterConfig } from '@ams-package/router';

import {
  Offers,
  TestModals,
  SignIn,
  Registration,
  TestButtons,
  TestDropdowns,
  TestFilters,
  Statistics,
  ForgotPassword,
  RestorePassword,
  OfferPage,
  Payouts,
  AccountSettings,
  Dashboard,
} from './pages';

export const routerConfig: IRouterConfig[] = [
  {
    name: 'Dashboard',
    icon: 'Dashboard',
    path: PathNames.dashboard,
    component: Dashboard,
    disabled: false,
    protected: true,
    isMenu: true,
  },
  {
    name: 'Offers',
    icon: 'Offers',
    path: PathNames.offers,
    component: Offers,
    disabled: false,
    protected: true,
    isMenu: true,
  },
  {
    name: 'Offer',
    icon: 'Offer',
    path: PathNames.offer,
    component: OfferPage,
    disabled: false,
    protected: true,
    isMenu: false,
  },
  {
    name: 'Statistics',
    icon: 'Statistics',
    path: PathNames.statistics,
    component: Statistics,
    disabled: false,
    protected: true,
    isMenu: true,
  },
  {
    name: 'Promo',
    icon: 'Promo',
    path: '/promo',
    component: () => <></>,
    disabled: true,
    protected: true,
    isMenu: true,
  },
  {
    name: 'Payouts',
    icon: 'Payouts',
    path: PathNames.payouts,
    component: Payouts,
    disabled: false,
    protected: true,
    isMenu: true,
  },
  {
    name: 'Account Settings',
    icon: 'AccountSettings',
    path: PathNames.accountSettings,
    component: AccountSettings,
    disabled: false,
    protected: true,
    isMenu: true,
  },
  {
    name: 'Login',
    path: PathNames.login,
    component: SignIn,
    protected: false,
  },
  {
    name: 'Registration',
    path: PathNames.registration,
    component: Registration,
    protected: false,
  },
  {
    name: 'Forgot Password',
    path: PathNames.resetPassword,
    component: ForgotPassword,
    protected: false,
  },
  {
    name: 'Restore Password',
    path: PathNames.restorePassword,
    component: RestorePassword,
    protected: false,
  },
  {
    // TODO: remove after adding storybook
    name: 'Test Modals',
    icon: 'testModals',
    path: PathNames.testModals,
    component: TestModals,
    protected: false,
    isMenu: false,
  },
  {
    // TODO: remove after adding storybook
    name: 'Test Buttons',
    path: PathNames.testButtons,
    component: TestButtons,
    protected: true,
    isMenu: false,
  },
  {
    // TODO: remove after adding storybook
    name: 'Test Modals',
    path: PathNames.testModals,
    component: TestModals,
    protected: true,
    isMenu: false,
  },
  {
    // TODO: remove after adding storybook
    name: 'Test Dropdowns',
    path: PathNames.testDropdowns,
    component: TestDropdowns,
    protected: true,
    isMenu: false,
  },
  {
    // TODO: remove after adding storybook
    name: 'Test Filters',
    path: PathNames.testFilters,
    component: TestFilters,
    protected: true,
    isMenu: false,
  },
];
