import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Notification } from '@xcritical/notification';

import App from '@ams-package/app';
import { getRouter } from '@ams-package/router';

import { store } from './store';
import { watchAppSaga } from './sagas';
import { AuthLayout, ProtectedLayout } from './components';
import { routerConfig } from './config';

store.runSaga(watchAppSaga);

ReactGA.initialize('G-9LYK22MG2C');

ReactDOM.render(
  <Provider store={store}>
    <App>
      <RouterProvider
        router={getRouter({
          AuthLayout,
          ProtectedLayout,
          routerConfig,
        })}
      />
      <Notification />
    </App>
  </Provider>,
  document.getElementById('root')
);
