import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@ams-package/button';

import { ILandingPage } from '../../types';
import { offersLinkQuerySelector } from '../../store';
import { LandingDescription } from '../styled';

import {
  CardWrapper,
  CardHeaderWrapper,
  LandingInfoWrapper,
  LandingPageButtonWrapper,
  LandingTitleWrapper,
} from './styled';
import { Links } from './Links';

interface ILandingPageProps extends ILandingPage {
  offerId: string;
}

export const LandingCard: React.FC<ILandingPageProps> = ({
  landingPageId,
  description,
  name,
  campaignId,
  url,
}) => {
  const { isDisabled } = useSelector(offersLinkQuerySelector);

  return (
    <CardWrapper key={`offer-landing-page-${landingPageId}`}>
      <CardHeaderWrapper>
        <LandingInfoWrapper>
          <LandingTitleWrapper>
            {name}
            <LandingPageButtonWrapper>
              <Button
                href={url}
                target="_blank"
                shouldFitContent
                rel="noreferrer"
                appearance="lightSecondary"
                disabled={isDisabled}>
                Preview
              </Button>
            </LandingPageButtonWrapper>
          </LandingTitleWrapper>
          {description && (
            <LandingDescription>{description}</LandingDescription>
          )}
        </LandingInfoWrapper>
      </CardHeaderWrapper>
      <Links landingPageId={landingPageId} campaignId={campaignId} />
    </CardWrapper>
  );
};
