import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'links-and-landings';

export const TabWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'tab-wrapper'),
})`
  width: 100%;
  display: flex;
  position: relative;
  flex: auto auto auto;
  justify-content: space-between;
  gap: 100px;
`;

// modal
export const ModalContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'modal-content-wrapper'),
})`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ModalDescriptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'modal-description-wrapper'),
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 16px;
`;

export const ModalButtonsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'modal-buttons-wrapper'),
})`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  button {
    padding: var(--account-info-buttons-padding);
  }
`;

// card
export const BodyWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'body-wrapper'),
})`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'card-wrapper'),
})`
  border-radius: 20px;
  background-color: var(--dark-grey);
  border: 1px solid var(--dark-grey);
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const CardHeaderWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'card-header-wrapper'),
})`
  display: flex;
  gap: var(--offers-card-title-wrapper-gap);
  border-bottom: 1px solid var(--secondary-border-color);
  padding-bottom: 16px;
  justify-content: space-between;
`;

export const LandingTitleWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'landing-title-wrapper'),
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const LandingInfoWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'landing-info-wrapper'),
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const LandingPageButtonWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'landing-page-button-wrapper'),
})`
  width: 250px;
  min-width: 250px;
`;

export const DescriptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'description-wrapper'),
})`
  display: flex;
  gap: 4px;
  flex-direction: column;
  ${generateFontStyle('body-2m')};
`;

// Link
export const LinksWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'links-wrapper'),
})`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CopyInputIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'copy-input-icon-wrapper'),
})`
  margin: 8px 0 0 -40px;
  cursor: pointer;
`;
