import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'statistics-page';

export const PageWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
`;

export const CalendarIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'calendar-icon-wrapper'),
})`
  margin-right: 6px;
  width: auto !important;
`;
