import React from 'react';
import { Grid } from 'styled-css-grid';

import Form, { FormField } from '@xcritical/forms';

import { InputFormField } from '@ams-package/input';
import { Label } from '@ams-package/label';

import { RESET_PASSWORD_FORM, RESET_PASSWORD_FORM_FIELDS } from '../consts';

export const ResetPasswordForm: React.FC = () => (
  <Form name={RESET_PASSWORD_FORM}>
    <Grid columns="1fr" gap="20px">
      <div>
        <Label isRequired htmlFor={RESET_PASSWORD_FORM_FIELDS.email}>
          Email address
        </Label>
        <FormField
          id={RESET_PASSWORD_FORM_FIELDS.email}
          name={RESET_PASSWORD_FORM_FIELDS.email}
          shouldFitContainer
          component={InputFormField}
          isClearable
          placeholder="Enter email"
        />
      </div>
    </Grid>
  </Form>
);
