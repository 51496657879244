import React from 'react';
import { NavLink } from 'react-router-dom';

import { svgComponents } from '@ams-package/icons';
import { LSHelper, LSKeys } from '@ams-package/utils';
import { PathNames } from '@ams-package/router';

import { Navigation } from '../navigation';
import { SidebarAccountInfo } from '../sidebar-account-info';

import {
  SidebarWrapper,
  SidebarHeader,
  SidebarFooter,
  SidebarLogout,
  SidebarLogoutLink,
  HeaderWrapper,
} from './styles';

export const Sidebar: React.FC = () => {
  const { LogoutIcon } = svgComponents;

  const removeToken = () => {
    LSHelper.removeItem(LSKeys.token);
    LSHelper.removeItem(LSKeys.tokenHealth);
  };

  return (
    <SidebarWrapper>
      <SidebarHeader>
        <NavLink to={PathNames.dashboard}>
          <HeaderWrapper />
        </NavLink>
        <Navigation />
      </SidebarHeader>
      <SidebarFooter>
        <SidebarAccountInfo />
        <SidebarLogout>
          <SidebarLogoutLink onClick={removeToken} to={PathNames.login}>
            <LogoutIcon />
            Log out
          </SidebarLogoutLink>
        </SidebarLogout>
      </SidebarFooter>
    </SidebarWrapper>
  );
};
