import { call, put, all, select } from 'typed-redux-saga';
import { getObjectWithoutUndefinedPropsFrom } from 'utilitify';
import isEmpty from 'lodash/isEmpty';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  xcriticalFormShowErrors,
  xcriticalFormError,
  xcriticalFormPropertyChange,
} from '@xcritical/forms';
import { xcriticalModalOpen } from '@xcritical/modal';

import { handleServerError } from '@ams-package/utils';
import {
  IDictionaries,
  loadAndSaveDictionariesByName,
} from '@ams-package/dictionaries';
import { StatusModalTypes } from '@ams-package/status-message';
import { OnSuccessActionPayload } from '@ams-package/payment-method-editor';
import { handleUpdateBalanceUser } from '@ams-package/app/sagas';
import { IUserBalanceModel } from '@ams-package/app';

import { PAYMENT_REQUEST_FORM, paymentRequestActions } from '../store';
import { ISubmitPaymentModel } from '../types';
import { submitSelector } from '../selectors';
import { PAYMENT_REQUEST_FIELDS } from '../consts';

import { apiCreatePayout } from './api';

export function* handleStartInit() {
  try {
    const [balance] = (yield* all([
      call(handleUpdateBalanceUser),
      loadAndSaveDictionariesByName([
        'paymentMethods',
        'payoutPaymentMethods',
        'texts',
        'payoutStatus',
      ]),
    ])) as unknown as readonly [IUserBalanceModel, IDictionaries];

    yield put(paymentRequestActions.finishInit(balance));
  } catch (e) {
    console.error(e);
    yield* handleServerError({ error: e });
  }
}

export function* handleAddPaymentMethod({
  payload,
}: PayloadAction<OnSuccessActionPayload>) {
  if (payload.formName === PAYMENT_REQUEST_FORM) {
    yield put(
      xcriticalFormPropertyChange(
        PAYMENT_REQUEST_FORM,
        PAYMENT_REQUEST_FIELDS.methodId,
        payload.id
      )
    );
  }
}

export function* handleSubmit() {
  try {
    const model = yield* select(submitSelector);

    const validationErrors = validateAmountModel(model);

    if (!isEmpty(validationErrors)) {
      yield* put(xcriticalFormShowErrors(PAYMENT_REQUEST_FORM, true));
      yield* put(xcriticalFormError(PAYMENT_REQUEST_FORM, validationErrors));

      return;
    }

    try {
      yield* call(apiCreatePayout, model);
    } catch (e) {
      yield* handleServerError({
        error: e,
        formName: PAYMENT_REQUEST_FORM,
        statusModalName: 'global',
      });

      return;
    }

    yield* put(
      xcriticalModalOpen('global', {
        message: {
          type: StatusModalTypes.Success,
          title: 'Request successfully accepted',
          description:
            'We have accepted the withdrawal request, when we approve it, the funds will be credited to your account. Keep in touch with your manager',
        },
      })
    );
  } catch (e) {
    yield* handleServerError({
      error: e,
    });
  }

  yield* put(paymentRequestActions.init());
}

export const validateAmountModel = ({
  currentPayment,
  balance,
  amount,
}: ISubmitPaymentModel) => {
  const errors = {};

  if (!currentPayment) {
    errors[PAYMENT_REQUEST_FIELDS.methodId] = 'Required Field';
  }

  if (!amount) {
    errors[PAYMENT_REQUEST_FIELDS.amount] = 'Required Field';
  } else if (
    currentPayment &&
    amount < currentPayment.payoutByRequestMinAmount
  ) {
    errors[
      PAYMENT_REQUEST_FIELDS.amount
    ] = `The amount for withdrawal must exceed $ ${currentPayment.payoutByRequestMinAmount}`;
  } else if (amount > balance) {
    errors[PAYMENT_REQUEST_FIELDS.amount] =
      'The amount exceeds available for withdrawal';
  }

  return getObjectWithoutUndefinedPropsFrom(errors);
};
