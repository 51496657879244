import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import Panel from '@ams-package/panel';
import { HeaderItemType } from '@ams-package/tabs';
import { generateFontStyle } from '@ams-package/theme';

const blockName = 'statistics';

export const StatisticsCard = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: flex-start;
`;

export const HeaderWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header-wrapper'),
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const TabsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'tabs-wrapper'),
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const TabsHeaderWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'tabs-header-wrapper'),
})`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 7px;
  border-radius: 10px;
  background: var(--tabs-background);
  padding: 6px;
`;

export const StatisticTab = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'statistic-tab'),
})<HeaderItemType>`
  ${generateFontStyle('body-1m')}
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  background: ${({ isActive }) =>
    isActive ? 'var(--primary-color)' : 'var(--tabs-background)'};
  color: ${({ isActive }) =>
    isActive ? 'var(--tabs-background)' : 'var(--tabs-color)'};
`;

export const StatisticBlockWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'statistic-block-wrapper'),
})`
  display: flex;
`;

export const IconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'icon-wrapper'),
})`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 44px;
  background: var(--base-black);
  margin-right: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const InfoWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'info-wrapper'),
})`
  display: flex;
  gap: 4px;
  flex-direction: column;
  color: var(--statistic-label-color);
  ${generateFontStyle('body-1r')}
`;

export const StatisticValue = styled.h3`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const PercentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'percent-wrapper'),
})<{ isPositive: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isPositive }) =>
    isPositive ? 'var(--primary-color)' : 'var(--danger-text-color)'};
  ${generateFontStyle('body-1sb')}

  svg {
    width: 18px;
    height: 18px;
    fill: ${({ isPositive }) =>
      isPositive ? 'var(--primary-color)' : 'var(--danger-text-color)'};
    path {
      stroke: ${({ isPositive }) =>
        isPositive ? 'var(--primary-color)' : 'var(--danger-text-color)'};
    }
  }
`;
