import React from 'react';

import Badge from '@xcritical/badge';

import { BadgeWrapper } from './styled';

export const PromoBadge: React.FC = () => (
  <BadgeWrapper>
    <Badge baseAppearance="default" appearance="info">
      SOON
    </Badge>
  </BadgeWrapper>
);
