import React from 'react';
import { Grid } from 'styled-css-grid';

import { Button } from '@ams-package/button';
import { Skeleton } from '@ams-package/skeleton';

import { PlusIcon, Wrapper, WrapperWithBorder } from './styled';

export const PaymentSettingsTabSkeleton: React.FC = () => (
  <Wrapper>
    <WrapperWithBorder>
      <h3>My payment methods</h3>
      <Grid columns="repeat(3, 1fr)" gap="16px">
        <Skeleton width={236} height={102} amount={3} />
      </Grid>
    </WrapperWithBorder>
    <WrapperWithBorder>
      <h3>Add payment methods</h3>
      <Grid columns="repeat(3, 1fr)" gap="16px">
        <Button prefix={<PlusIcon />} disabled>
          Add payment method
        </Button>
      </Grid>
    </WrapperWithBorder>
    <Skeleton width={500} height={64} />
    <Skeleton width={400} height={24} />
  </Wrapper>
);
