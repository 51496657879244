import React from 'react';

import { HeaderItemType, ITabPanel } from '@ams-package/tabs/types';

export type Tab = {
  TabIcon?: React.ElementType;
  key?: string;
  title?: string;
  HeaderItem?: React.FC<HeaderItemType>;
  Content: React.ElementType;
  disabled?: boolean;
  TitleBadge?: React.ElementType;
  url?: string;
};

export const createTabsConfig = (formattedData: Tab[]): ITabPanel[] | null => {
  if (!formattedData.length) return null;

  return formattedData.reduce<ITabPanel[]>(
    (
      tabs,
      {
        TabIcon,
        title,
        Content,
        disabled = false,
        TitleBadge,
        HeaderItem,
        key,
        url,
      },
      index
    ) => [
      ...tabs,
      {
        key: key ?? title ?? index,
        url,
        header: (
          <>
            {TabIcon && <TabIcon />}
            {title}
            {TitleBadge && <TitleBadge />}
          </>
        ),
        HeaderItem,
        content: <Content />,
        disabled,
      },
    ],
    []
  );
};
