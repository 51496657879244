import { call, select } from 'typed-redux-saga';
import isEmpty from 'lodash/isEmpty';
import { put } from 'redux-saga/effects';

import { notify } from '@xcritical/notification';
import {
  xcriticalFormError,
  xcriticalFormReset,
  xcriticalFormShowErrors,
} from '@xcritical/forms';

import { registryActions } from '@crm-framework/registry';

import { handleServerError } from '@ams-package/utils';

import { changePasswordFormSelector } from '../store';
import { CHANGE_PASSWORD_FORM, CHANGE_PASSWORD_IS_LOADING } from '../consts';

import { changePassword } from './api';
import { validateChangePasswordModel } from './validation';

export function* handleChangePassword(): Generator {
  try {
    yield put(registryActions.set(CHANGE_PASSWORD_IS_LOADING, true));
    const { model } = yield* select(changePasswordFormSelector);

    const validationErrors = validateChangePasswordModel(model);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(CHANGE_PASSWORD_FORM, true));
      yield put(xcriticalFormError(CHANGE_PASSWORD_FORM, validationErrors));

      return;
    }

    yield* call(changePassword, model);

    notify.success('New password saved successfully');
    yield put(xcriticalFormReset(CHANGE_PASSWORD_FORM));
  } catch (error) {
    yield call(handleServerError, { error, formName: CHANGE_PASSWORD_FORM });
  } finally {
    yield put(registryActions.set(CHANGE_PASSWORD_IS_LOADING, false));
  }
}
