import React from 'react';
import PlusIcon from 'mdi-react/PlusIcon';

import { FormField } from '@xcritical/forms';

import {
  PasswordFormField,
  PasswordValidationFormField,
} from '@ams-package/input';
import { BaseButton, Button } from '@ams-package/button';

export const TestButtons: React.FC = (): any => (
  <div
    style={{
      width: '300px',
      padding: '10px',
      height: '1000px',
      backgroundColor: 'rgb(123, 123, 123)',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    }}>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <FormField
        id="password-form-field"
        name="{FIELDS.name}"
        shouldFitContainer
        component={PasswordFormField}
        isClearable
        placeholder="Enter amount"
      />
      <FormField
        id="password-form-field2"
        name="{FIELDS.namsdfsde}"
        shouldFitContainer
        component={PasswordValidationFormField}
        isClearable
        placeholder="Enter amount"
      />
      <BaseButton appearance="primaryLink">Withdraw money</BaseButton>
      <Button appearance="primaryLink">Withdraw money</Button>
      <Button appearance="primaryLink" disabled>
        Withdraw money
      </Button>
      <Button appearance="link">Withdraw money</Button>
      <Button appearance="link" disabled>
        Withdraw money
      </Button>
      <Button appearance="info" prefix={<PlusIcon size={16} />}>
        Withdraw money
      </Button>
      <Button appearance="info" disabled>
        Withdraw money
      </Button>
      <Button appearance="secondary" postfix={<PlusIcon size={16} />}>
        Withdraw money
      </Button>
      <Button appearance="secondary" disabled>
        Withdraw money
      </Button>
      <Button appearance="light">Withdraw money</Button>
      <Button appearance="light" disabled>
        Withdraw money
      </Button>
      <Button appearance="default" disabled>
        Withdraw money
      </Button>
      <Button appearance="default">Withdraw money</Button>
    </div>
  </div>
);
