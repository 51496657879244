import React, { useCallback, useEffect } from 'react';
import { Grid } from 'styled-css-grid';
import { useDispatch } from 'react-redux';

import { useDictionary } from '@crm-framework/dictionaries';
import { useRegistry } from '@crm-framework/registry';

import { Button } from '@ams-package/button';
import { paymentEditorActions } from '@ams-package/payment-method-editor';
import {
  IPayoutPaymentMethodConfigurationModel,
  IPaymentMethodsModel,
} from '@ams-package/dictionaries';
import { defaultImages } from '@ams-package/icons';

import { accountSettingsActions } from '../../store';
import { PAYMENT_SETTINGS_IS_LOADING } from '../../consts';

import {
  EmptyMessage,
  PaymentIcon,
  PlusIcon,
  SupportedPaymentIconWrapper,
  SupportedPaymentsWrapper,
  Wrapper,
  WrapperWithBorder,
} from './styled';
import { PaymentMethod } from './PaymentMethod';
import { PaymentsInfo } from './PaymentsInfo';
import { PaymentSettingsTabSkeleton } from './_PaymentSettingsTabSkeleton';

export const PaymentSettingsTab: React.FC = () => {
  const dispatch = useDispatch();
  const paymentMethods = useDictionary(
    'paymentMethods'
  ) as IPaymentMethodsModel[];
  const availablePaymentMethods = useDictionary(
    'payoutPaymentMethods'
  ) as IPayoutPaymentMethodConfigurationModel[];
  const isLoading = useRegistry<boolean>(PAYMENT_SETTINGS_IS_LOADING, true);

  useEffect(() => {
    dispatch(accountSettingsActions.initPaymentSettings());
  }, []);

  const onClick = useCallback(() => {
    dispatch(paymentEditorActions.addOrEdit());
  }, []);

  if (isLoading) return <PaymentSettingsTabSkeleton />;

  return (
    <Wrapper>
      <WrapperWithBorder>
        <h3>My payment methods</h3>
        {paymentMethods.length ? (
          <Grid columns="repeat(3, 1fr)" gap="16px">
            {paymentMethods.map((item) => (
              <PaymentMethod key={item.id} {...item} />
            ))}
          </Grid>
        ) : (
          <EmptyMessage>
            You don&apos;t have any payment methods added yet
          </EmptyMessage>
        )}
      </WrapperWithBorder>
      <WrapperWithBorder>
        <h3>Add payment methods</h3>
        <Grid columns="repeat(3, 1fr)" gap="16px">
          <Button
            prefix={<PlusIcon />}
            onClick={onClick}
            appearance="addPaymentMethod">
            Add payment method
          </Button>
        </Grid>
      </WrapperWithBorder>
      <SupportedPaymentsWrapper>
        {availablePaymentMethods.map((item) => (
          <SupportedPaymentIconWrapper key={item.id}>
            <PaymentIcon src={defaultImages[item.id]} />
          </SupportedPaymentIconWrapper>
        ))}
      </SupportedPaymentsWrapper>
      <PaymentsInfo shouldInit />
    </Wrapper>
  );
};
