import { put } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { handleServerError } from '@ams-package/utils';

import { offersActions } from '../store';

import { getOffers } from './api';

export function* handleOffersInit(): Generator {
  try {
    yield put(offersActions.setIsReady(false));
    yield handleAllOffersInit();
    yield handleMyOffersInit();
    yield handleAvailableOffersInit();
  } catch (error) {
    handleServerError({ error });
  } finally {
    yield put(offersActions.setIsReady(true));
  }
}

function* handleAllOffersInit(): Generator {
  try {
    const allOffers = yield* call(getOffers, { isMine: null });

    yield put(offersActions.setAllOffersData(allOffers));
  } catch (error) {
    handleServerError({ error });
  }
}

function* handleMyOffersInit(): Generator {
  try {
    const myOffers = yield* call(getOffers, { isMine: true });

    yield put(offersActions.setMyOffersData(myOffers));
  } catch (error) {
    handleServerError({ error });
  }
}

function* handleAvailableOffersInit(): Generator {
  try {
    const availableOffers = yield* call(getOffers, { isMine: false });

    yield put(offersActions.setAvailableOffersData(availableOffers));
  } catch (error) {
    handleServerError({ error });
  }
}
