import { put, takeLatest } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleServerError } from '@ams-package/utils';

import { mainDashboardActions } from '../store';
import { getOffers } from '../../offers';
import { statisticPeriods } from '../components';
import { mapStatisticModel } from '../utils';
import { getAffiliatesStatistics } from '../../statistics';

import { getDashboardConfig } from './api';

export function* watchMainDashboardSagas() {
  yield takeLatest(mainDashboardActions.init.type, handleInit);
  yield takeLatest(
    mainDashboardActions.getAffStatistics,
    handleGetAffStatistics
  );
}

export function* handleInit() {
  try {
    const config = yield* call(getDashboardConfig);
    yield put(mainDashboardActions.setConfig(config));

    if (!config.signups) return;

    const topOffers = yield* call(getOffers, { isTopOffer: true });
    yield put(mainDashboardActions.setTopOffers(topOffers));

    yield call(
      handleGetAffStatistics,
      mainDashboardActions.getAffStatistics(statisticPeriods.day.name)
    );
  } catch (error) {
    handleServerError({ error });
  } finally {
    yield put(mainDashboardActions.setIsReady(true));
  }
}

export function* handleGetAffStatistics({
  payload: period,
}: PayloadAction<number | string>) {
  try {
    const selectedPeriod = statisticPeriods[period];

    if (!selectedPeriod) return;

    const { end, start, endPrevious, startPrevious } = selectedPeriod;

    const fixedRequestOptions = {
      groupKey: 'allTime',
      limit: 1,
      offset: 0,
    };

    const affStatistics = yield* call(getAffiliatesStatistics, {
      dateFrom: start,
      dateTo: end,
      ...fixedRequestOptions,
    });

    const prevPeriodAffStatistics =
      startPrevious && endPrevious
        ? yield* call(getAffiliatesStatistics, {
            dateFrom: startPrevious,
            dateTo: endPrevious,
            ...fixedRequestOptions,
          })
        : null;

    const statisticModel = affStatistics.data[0] ?? {};
    const prevStatisticModel = prevPeriodAffStatistics?.data[0] ?? {};

    const statistics = mapStatisticModel(statisticModel, prevStatisticModel);

    yield put(mainDashboardActions.setAffStatistics(statistics));
  } catch (error) {
    handleServerError({ error });
  }
}
