import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tabs } from '@ams-package/tabs';
import { svgComponents, ChevronIcon } from '@ams-package/icons';
import { IconWrapper } from '@ams-package/utils';

import { createTabsConfig } from '../../utils';

import { offerNameSelector, offersActions } from './store';
import { OfferCssStyles } from './styled';
import {
  LinkAndLandingTab,
  BackButtonWrapper,
  HeaderWrapper,
  PromoBadge,
  InformationTab,
} from './offer';

const PromoTab: React.FC = () => <></>;

export const OfferPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const offerName = useSelector(offerNameSelector);

  useEffect(() => {
    if (id) dispatch(offersActions.initOffer({ offerId: id }));
  }, [id]);

  const handleGoToOffers = () => {
    navigate('/offers');
  };

  const tabData = useMemo(
    () => [
      {
        TabIcon: svgComponents.LinkAndLandingIcon,
        title: 'Links & Landing pages',
        Content: LinkAndLandingTab,
        key: 'landings',
      },
      {
        TabIcon: svgComponents.InformationIcon,
        title: 'Information',
        Content: InformationTab,
        key: 'information',
      },
      {
        TabIcon: svgComponents.Promo,
        title: 'Promo',
        Content: PromoTab,
        disabled: true,
        TitleBadge: PromoBadge,
        key: 'promo',
      },
    ],
    []
  );

  const tabPanels = useMemo(() => createTabsConfig(tabData), [tabData]);

  return (
    <>
      <OfferCssStyles />
      <HeaderWrapper>
        <BackButtonWrapper onClick={handleGoToOffers}>
          <IconWrapper>
            <ChevronIcon />
          </IconWrapper>
        </BackButtonWrapper>
        <h1>{offerName}</h1>
      </HeaderWrapper>
      {tabPanels && <Tabs tabPanels={tabPanels} />}
    </>
  );
};
