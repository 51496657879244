import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Skeleton } from '@ams-package/skeleton';

import { IOffer } from '../types';
import { isReadySelector } from '../store';

import { OffersCard } from './offers-card';
import { EmptyOffers } from './EmptyOffers';

export const OffersTab: React.FC<{
  data: IOffer[];
  order: number;
}> = memo(({ data, order }) => {
  const isReady = useSelector(isReadySelector);

  if (!isReady) return <Skeleton height={196} amount={3} />;

  if (order === 2 && !data.length) return <EmptyOffers />;

  return (
    <>
      {data.map(({ id, ...props }) => (
        <OffersCard key={`offer-card-${order}-${id}`} id={id} {...props} />
      ))}
    </>
  );
});
