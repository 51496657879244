import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getUser } from '@ams-package/app';
import { PathNames } from '@ams-package/router';

import { IOffer } from '../../types';
import { offersActions } from '../../store';

import {
  CardWrapper,
  CardBody,
  CardBodyColumn,
  CardColumnTitle,
  CardColumnValue,
  CardStatusColumnValue,
} from './styled';
import { OfferCardHeader } from './OfferCardHeader';

export const OffersCard: React.FC<IOffer> = ({
  id,
  name,
  // TODO: change isActivated & isOfferActive to one boolean prop after backend will change it
  isActivated,
  isOfferActive,
  offerDealType,
  offerDealValue,
  offerDealKey,
  isTopOffer,
  countries,
  landingPagesRedirectLinks,
  links: { mixedTraffic },
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const navigate = useNavigate();
  const dealAmount = () => {
    if (!offerDealKey) return '';

    return offerDealValue;
  };
  const COLUMN_VALUES = {
    offerDealAmount: dealAmount(),
    offerDealType,
    countries,
    isOfferActive: isOfferActive && isActivated ? 'Active' : 'Not Active',
  };

  const TITLES = useMemo(
    () => ({
      isOfferActive: 'Status',
      offerDealType: 'Offer Type',
      offerDealAmount: offerDealKey ? `${offerDealKey}` : '',
      countries: 'Countries',
    }),
    [offerDealKey]
  );

  const linkOfFirstLanding = useMemo(() => {
    if (!landingPagesRedirectLinks?.[0] || !user) return null;

    const { campaignId, landingPageId } = landingPagesRedirectLinks[0];

    return !landingPageId || !campaignId
      ? ''
      : `${mixedTraffic}/?affiliateId=${user.id}${
          campaignId ? `&campaignId=${campaignId}` : ''
        }${landingPageId ? `&landingPageId=${landingPageId}` : ''}`;
  }, [mixedTraffic, landingPagesRedirectLinks, user]);

  const handleOpenCard = useCallback(() => {
    dispatch(offersActions.resetSelectedOffer());
    navigate(PathNames.offer.replace(':id', String(id)));
  }, [id]);

  return (
    <CardWrapper key={`offer-card-content-${id}`} onClick={handleOpenCard}>
      <OfferCardHeader
        id={id}
        name={name}
        isTopOffer={isTopOffer}
        handlerOpenCard={handleOpenCard}
        link={linkOfFirstLanding}
        hasOneLanding={landingPagesRedirectLinks?.length === 1}
        isActive={isOfferActive && isActivated}
      />
      <CardBody>
        {Object.keys(TITLES).map((column) => (
          <CardBodyColumn key={TITLES[column]}>
            <CardColumnTitle>{TITLES[column]}</CardColumnTitle>
            {TITLES[column] === TITLES.isOfferActive ? (
              <CardStatusColumnValue isActive={isOfferActive && isActivated}>
                {COLUMN_VALUES[column]}
              </CardStatusColumnValue>
            ) : (
              <CardColumnValue title={COLUMN_VALUES[column]}>
                {COLUMN_VALUES[column]}
              </CardColumnValue>
            )}
          </CardBodyColumn>
        ))}
      </CardBody>
    </CardWrapper>
  );
};
