import React from 'react';
import { Grid } from 'styled-css-grid';

import { Label } from '@ams-package/label';
import { Button } from '@ams-package/button';
import { Skeleton } from '@ams-package/skeleton';

import {
  PERSONAL_INFO_GRID_SETTINGS,
  PERSONAL_INFO_REQUIRED_FIELDS,
} from '../consts';

import { SubmitButtonWrapper } from './styled';

export const PersonalInfoTabSkeleton: React.FC = () => (
  <Grid {...PERSONAL_INFO_GRID_SETTINGS}>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.email}>
        Email address
      </Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.firstname}>
        First Name
      </Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.lastname}>
        Last Name
      </Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.phone}>Phone</Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.birthday}>
        Date of Birth
      </Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.country}>Country</Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.whatsApp}>
        WhatsApp
      </Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.viber}>Viber</Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.telegram}>
        Telegram
      </Label>
      <Skeleton height={48} width={342} />
    </div>
    <div>
      <Label isRequired={PERSONAL_INFO_REQUIRED_FIELDS.skype}>Skype</Label>
      <Skeleton height={48} width={342} />
    </div>
    <SubmitButtonWrapper>
      <Button shouldFitContent disabled>
        Save
      </Button>
    </SubmitButtonWrapper>
  </Grid>
);
