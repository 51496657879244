import React from 'react';
import { useSelector } from 'react-redux';

import { signUpInProgress } from '../store';

import { PersonalDataForm } from './PersonalDataForm';
import { SignInForm } from './SignInForm';
import {
  DescriptionWrapper,
  BottomDescriptionWrapper,
  BottomContentWrapper,
} from './styled';

const FIRST_STEP_DESCRIPTION = 'Enter your email and password to register';
const SECOND_STEP_DESCRIPTION =
  'Their implementation is important. You can change them later in your personal account';

export const SideModalContent: React.VFC<{ pageButton?: React.ReactNode }> = ({
  pageButton,
}) => {
  const isInProgress = useSelector(signUpInProgress);

  return (
    <>
      <DescriptionWrapper>
        {!isInProgress ? FIRST_STEP_DESCRIPTION : SECOND_STEP_DESCRIPTION}
      </DescriptionWrapper>
      {!isInProgress ? <SignInForm isSignUp /> : <PersonalDataForm />}
      <BottomContentWrapper>
        {pageButton}
        <BottomDescriptionWrapper>
          Already have an account?{' '}
          <a href={`${window.location.origin}/login`}>Sign in</a>
        </BottomDescriptionWrapper>
      </BottomContentWrapper>
    </>
  );
};
