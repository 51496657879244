import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Badge from '@xcritical/badge';
import Form, { FormField, xcriticalFormError } from '@xcritical/forms';

import { toCurrency } from '@ams-package/utils';
import { Label } from '@ams-package/label';
import { InputFormField } from '@ams-package/input';
import { SelectPaymentMethod } from '@ams-package/payment-method-editor';
import { getUser } from '@ams-package/app';
import { Skeleton } from '@ams-package/skeleton';

import {
  CloseCircleIcon,
  PaymentAmount,
  PaymentAvailableBalance,
  PaymentButton,
  PaymentCheckMessage,
  PaymentMethod,
  PaymentRequestCheckStatus,
  PaymentRequestContainer,
  PaymentRequestTitle,
  TickCircleIcon,
  MinimumAmountWrapper,
} from './styled';
import { PAYMENT_REQUEST_FORM, paymentRequestActions } from './store';
import { viewSelector } from './selectors';
import { PAYMENT_REQUEST_FIELDS } from './consts';

export const PaymentRequest = () => {
  const user = useSelector(getUser);
  const { balance, availableWithdrawal, minBalance, selectedPayment, isReady } =
    useSelector(viewSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(paymentRequestActions.init());
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(paymentRequestActions.submit());
  }, []);

  const handleChangeMethod = useCallback(() => {
    dispatch(
      xcriticalFormError(PAYMENT_REQUEST_FORM, {
        [PAYMENT_REQUEST_FIELDS.amount]: '',
      })
    );
  }, []);

  return (
    <Form name={PAYMENT_REQUEST_FORM}>
      <PaymentRequestContainer>
        <PaymentRequestTitle>Payout Requests</PaymentRequestTitle>
        <PaymentRequestCheckStatus>
          {isReady ? (
            <Badge
              baseAppearance="default"
              appearance={availableWithdrawal ? 'primary' : 'danger'}>
              {availableWithdrawal ? 'Activated' : 'Not available'}
            </Badge>
          ) : (
            <Skeleton height={20} width={70} color="var(--background-color)" />
          )}
        </PaymentRequestCheckStatus>
        <PaymentCheckMessage>
          {availableWithdrawal ? (
            <TickCircleIcon size={16} />
          ) : (
            <CloseCircleIcon size={16} />
          )}
          &nbsp;Have at least ${minBalance} in available balance
        </PaymentCheckMessage>
        <PaymentAvailableBalance>
          Available for withdrawal
          <h2>$ {toCurrency(balance) || user?.balance}</h2>
        </PaymentAvailableBalance>
        <PaymentAmount>
          <Label isRequired htmlFor={PAYMENT_REQUEST_FIELDS.amount}>
            Amount
          </Label>
          <FormField
            id={PAYMENT_REQUEST_FIELDS.amount}
            name={PAYMENT_REQUEST_FIELDS.amount}
            shouldFitContainer
            disabled={!availableWithdrawal}
            component={InputFormField}
            isClearable
            placeholder="Enter amount"
          />
        </PaymentAmount>

        <PaymentMethod>
          <Label isRequired htmlFor={PAYMENT_REQUEST_FIELDS.methodId}>
            Payment methods
          </Label>
          <FormField
            id={PAYMENT_REQUEST_FIELDS.methodId}
            name={PAYMENT_REQUEST_FIELDS.methodId}
            shouldFitContainer
            component={SelectPaymentMethod}
            formName={PAYMENT_REQUEST_FORM}
            onChange={handleChangeMethod}
            disabled={!availableWithdrawal}
            isClearable
            placeholder="Not selected"
          />
          <MinimumAmountWrapper>
            {selectedPayment
              ? `The minimum payout amount for ${selectedPayment.name} is $${selectedPayment.payoutByRequestMinAmount}`
              : ''}
          </MinimumAmountWrapper>
        </PaymentMethod>
        <PaymentButton disabled={!availableWithdrawal} onClick={handleSubmit}>
          Withdraw money
        </PaymentButton>
      </PaymentRequestContainer>
    </Form>
  );
};
