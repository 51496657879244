import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Badge from '@xcritical/badge';
import Form, { FormField } from '@xcritical/forms';

import { Label } from '@ams-package/label';
import { SelectPaymentMethod } from '@ams-package/payment-method-editor';
import { Skeleton } from '@ams-package/skeleton';

import {
  CloseCircleIcon,
  PaymentCheckMessage,
  PaymentRequestCheckStatus,
  PaymentRequestTitle,
  RegularPayoutField,
  RegularPayoutContainer,
  TickCircleIcon,
  UpcomingDate,
  UpcomingDateLabel,
  MinimumAmountWrapper,
  RegularPayoutGrid,
} from './styled';
import { regularPayoutActions, REGULAR_PAYOUT_FORM } from './store';
import { regularPayoutsViewSelector } from './selectors';
import { REGULAR_PAYOUT_FIELDS } from './consts';

export const RegularPayout = () => {
  const dispatch = useDispatch();
  const {
    availableWithdrawal,
    minBalance,
    isReady,
    payoutDate,
    selectedPayment,
  } = useSelector(regularPayoutsViewSelector);

  useEffect(() => {
    dispatch(regularPayoutActions.initWidget());
  }, []);

  const handleChangeMethod = useCallback((paymentMethodId: number) => {
    if (paymentMethodId) {
      dispatch(regularPayoutActions.select(paymentMethodId));
    } else {
      dispatch(regularPayoutActions.delete());
    }
  }, []);

  return (
    <Form name={REGULAR_PAYOUT_FORM}>
      <RegularPayoutContainer>
        <RegularPayoutGrid>
          <PaymentRequestTitle>Regular Payouts</PaymentRequestTitle>
          <PaymentRequestCheckStatus>
            {isReady ? (
              <Badge
                baseAppearance="default"
                appearance={availableWithdrawal ? 'primary' : 'danger'}>
                {availableWithdrawal ? 'Activated' : 'Not available'}
              </Badge>
            ) : (
              <Skeleton
                height={20}
                width={70}
                color="var(--background-color)"
              />
            )}
          </PaymentRequestCheckStatus>
          <PaymentCheckMessage>
            {availableWithdrawal ? (
              <TickCircleIcon size={16} />
            ) : (
              <CloseCircleIcon size={16} />
            )}
            &nbsp;Have at least ${minBalance} in available balance
          </PaymentCheckMessage>
          {payoutDate && (
            <UpcomingDate>
              <UpcomingDateLabel>Upcoming payout</UpcomingDateLabel>
              <h2>{payoutDate}</h2>
            </UpcomingDate>
          )}
          <RegularPayoutField>
            <Label isRequired htmlFor={REGULAR_PAYOUT_FIELDS.methodId}>
              Payment methods
            </Label>
            <FormField
              id={REGULAR_PAYOUT_FIELDS.methodId}
              name={REGULAR_PAYOUT_FIELDS.methodId}
              shouldFitContainer
              formName={REGULAR_PAYOUT_FORM}
              component={SelectPaymentMethod}
              onChange={handleChangeMethod}
              disabled={!availableWithdrawal}
              isClearable
              statusMessageKey="regularPayouts"
              placeholder="Not selected"
            />
            <MinimumAmountWrapper>
              {selectedPayment
                ? `The minimum payout amount for ${selectedPayment.name} is $${selectedPayment.payoutByRequestMinAmount}`
                : ''}
            </MinimumAmountWrapper>
          </RegularPayoutField>
        </RegularPayoutGrid>
      </RegularPayoutContainer>
    </Form>
  );
};
