import React from 'react';

import { DashboardSettingsType } from '@ams-package/dashboard';
import { FilterTypes } from '@ams-package/filters';
import { BaseCell } from '@ams-package/grid';
import { svgComponents } from '@ams-package/icons';

import { CalendarIconWrapper, StatusSnippet } from './styled';

export const settings: DashboardSettingsType[] = [
  {
    name: 'id',
    displayName: 'Id',
    width: 80,
    inGrid: true,
    inFilter: false,
  },
  {
    name: 'date',
    displayName: 'Date',
    filterType: FilterTypes.Date,
    inGrid: true,
    width: 150,
    inFilter: true,
    inSort: true,
    render: (value: string) => (
      <BaseCell>
        <CalendarIconWrapper>
          <svgComponents.Calendar />
        </CalendarIconWrapper>
        {new Date(value).toLocaleDateString()}
      </BaseCell>
    ),
  },
  {
    name: 'type',
    displayName: 'Type',
    filterType: FilterTypes.SingleSelect,
    dictionaryName: 'payoutType',
    width: 150,
    inGrid: true,
    inFilter: true,
    render(_, __, row) {
      return <BaseCell>{row.typeName}</BaseCell>;
    },
  },
  {
    name: 'method',
    width: 150,
    displayName: 'Method',
    filterType: FilterTypes.MultiSelect,
    inGrid: true,
    inFilter: false,
  },
  {
    name: 'status',
    displayName: 'Status',
    width: 150,
    filterType: FilterTypes.MultiSelect,
    dictionaryName: 'payoutStatus',
    inGrid: true,
    inFilter: true,
    render(_, __, row) {
      return (
        <StatusSnippet status={_}>
          {row.statusName === 'Rejected' ? 'Cancelled' : row.statusName}
        </StatusSnippet>
      );
    },
  },
  {
    name: 'amount',
    displayName: 'Amount',
    inGrid: true,
    inFilter: false,
  },
];
