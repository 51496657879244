import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { SideModal } from '@ams-package/side-modal';
import { Button } from '@ams-package/button';
import { StatusMessage, StatusModalTypes } from '@ams-package/status-message';
import { IconWrapper, useKeyPress } from '@ams-package/utils';
import { ChevronIcon } from '@ams-package/icons';

import {
  DescriptionWrapper,
  BottomContentWrapper,
  ResetPasswordForm,
  SideModalTitleWrapper,
} from './components';
import { getIsPasswordReset, loginActions } from './store';
import {
  LoginPageWrapper,
  LoginHeader,
  BackButtonWrapper,
  StatusMessageWrapper,
} from './styled';
import { HEADER } from './consts';

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isReset = useSelector(getIsPasswordReset);

  const handlerResetPassword = () => {
    dispatch(loginActions.resetPassword());
  };

  const handlerGoToLogin = () => {
    dispatch(loginActions.setIsPasswordReset(false));
    navigate('/login');
  };

  // TODO handle case when we have few forms on the page
  useKeyPress('Enter', isReset ? handlerGoToLogin : handlerResetPassword);

  return (
    <>
      <LoginHeader>{HEADER}</LoginHeader>
      <LoginPageWrapper>
        <SideModal
          isOpen
          title={
            isReset ? (
              ''
            ) : (
              <>
                <BackButtonWrapper onClick={handlerGoToLogin}>
                  <IconWrapper>
                    <ChevronIcon />
                  </IconWrapper>
                </BackButtonWrapper>
                <SideModalTitleWrapper>
                  Forgot your password?
                </SideModalTitleWrapper>
              </>
            )
          }
          appearance="login"
          withCloseButton={false}>
          {isReset ? (
            <>
              <StatusMessageWrapper>
                <StatusMessage
                  type={StatusModalTypes.Success}
                  title="Password Reset Link Sent"
                  description="We have sent a reset email to your email. If you didn't receive an email, please check your spam or request a second request."
                />
              </StatusMessageWrapper>
              <BottomContentWrapper>
                <Button shouldFitContent onClick={handlerGoToLogin}>
                  Back to login
                </Button>
              </BottomContentWrapper>
            </>
          ) : (
            <>
              <div>
                <DescriptionWrapper>
                  Enter your registered email below to receive password reset
                  instruction
                </DescriptionWrapper>
                <ResetPasswordForm />
              </div>
              <BottomContentWrapper>
                <Button shouldFitContent onClick={handlerResetPassword}>
                  Send
                </Button>
              </BottomContentWrapper>
            </>
          )}
        </SideModal>
      </LoginPageWrapper>
    </>
  );
};
