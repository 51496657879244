import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { STORE_NAME } from '../consts';
import {
  IOffer,
  IOffersState,
  IOfferData,
  ILandingPage,
  ILandingPagesPayload,
  IOfferInitPayload,
} from '../types';

import { sortByIsTopOffer } from './utils';
export * from './selectors';

export const initialState: IOffersState = {
  myOffersData: [],
  myOffersDataCount: 0,
  allOffersData: [],
  allOffersDataCount: 0,
  availableOffersData: [],
  availableOffersDataCount: 0,
  offerData: null,
  landingPages: null,
  lastCopiedLink: null,
  isReady: false,
};

const offersStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setIsReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isReady = payload;
    },
    // offers
    setMyOffersData: (state, { payload }: PayloadAction<IOffer[]>) => {
      state.myOffersData = sortByIsTopOffer(payload);
      state.myOffersDataCount = payload.length;
    },
    setAllOffersData: (state, { payload }: PayloadAction<IOffer[]>) => {
      state.allOffersData = sortByIsTopOffer(payload);
      state.allOffersDataCount = payload.length;
    },
    setAvailableOffersData: (state, { payload }: PayloadAction<IOffer[]>) => {
      state.availableOffersData = sortByIsTopOffer(payload);
      state.availableOffersDataCount = payload.length;
    },
    // offer
    setSelectedOffer: (state, { payload }: PayloadAction<IOfferData>) => {
      state.offerData = payload;
    },
    setLandingPages: (state, { payload }: PayloadAction<ILandingPage[]>) => {
      state.landingPages = [...payload].sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1
      );
    },
    setLastCopiedLink: (state, { payload }: PayloadAction<string>) => {
      state.lastCopiedLink = payload;
    },
    resetSelectedOffer: (state) => {
      state.offerData = null;
      state.landingPages = null;
    },
  },
});

const OFFERS_INIT = `${STORE_NAME}/offers-init`;
const OFFER_INIT = `${STORE_NAME}/offer-init`;
const GET_LANDING_PAGES = `${STORE_NAME}/get-landing-pages`;

export const offersActions = {
  ...offersStore.actions,
  initOffers: createAction(OFFERS_INIT),
  initOffer: createAction<IOfferInitPayload>(OFFER_INIT),
  getLandingPages: createAction<ILandingPagesPayload>(GET_LANDING_PAGES),
};

export const offersReducer = offersStore.reducer;
