import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { offerLandingsSelector } from '../../store';
import { ColumnWrapper } from '../styled';

import { LandingCard } from './LandingCard';
import { BodyWrapper } from './styled';

export const Landings: React.FC = () => {
  const landings = useSelector(offerLandingsSelector);
  const { id } = useParams();

  return (
    <ColumnWrapper>
      {id && (
        <BodyWrapper>
          {landings?.map((landing) => (
            <LandingCard
              key={`offer-landing-card-${landing.landingPageId}`}
              offerId={id}
              {...landing}
            />
          ))}
        </BodyWrapper>
      )}
    </ColumnWrapper>
  );
};
