import React, { useEffect } from 'react';
import { Grid } from 'styled-css-grid';
import { useDispatch, useSelector } from 'react-redux';

import PaymentRequest from '../../components/payment-request';

import { DashboardWrapper, GetStartedTitle } from './styled';
import { Statistics, Steps, TopOffers } from './components';
import {
  dashboardConfigSelector,
  isDashboardReadySelector,
  mainDashboardActions,
} from './store';
import { steps } from './consts';
import { DashboardSkeleton } from './_DashboardSkeleton';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const config = useSelector(dashboardConfigSelector);
  const isReady = useSelector(isDashboardReadySelector);

  useEffect(() => {
    dispatch(mainDashboardActions.init());
  }, []);

  if (!isReady) {
    return <DashboardSkeleton />;
  }

  return (
    <DashboardWrapper>
      <h1>Dashboard</h1>
      {!config?.signups ? (
        <>
          <GetStartedTitle>Get started in 4 easy steps:</GetStartedTitle>
          <Steps steps={steps} />
        </>
      ) : (
        <>
          <Grid columns="1fr 1fr" gap="16px">
            <PaymentRequest />
            <Statistics />
          </Grid>
          <TopOffers />
        </>
      )}
    </DashboardWrapper>
  );
};
