import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { StatusModal, ConfirmModal } from '@ams-package/modal';
import PaymentEditor from '@ams-package/payment-method-editor';

import { Header } from '../header';
import { Sidebar } from '../sidebar';
import { REGULAR_PAYOUT_STATUS_MESSAGES } from '../regular-payouts';

import { Main, Content, ProtectedLayoutContainer } from './styles';

export const ProtectedLayout: React.FC = () => {
  const navigate = useNavigate();

  // TODO: make method to check if user is authenticated
  const isAuthenticated = true;

  if (!isAuthenticated) {
    navigate('/login');
  }

  return (
    <ProtectedLayoutContainer>
      <Sidebar />
      <Main>
        <Header />
        <Content>
          <Outlet />
        </Content>
      </Main>
      <StatusModal name="global" />
      <ConfirmModal name="confirm" />
      <PaymentEditor statusMessages={REGULAR_PAYOUT_STATUS_MESSAGES} />
    </ProtectedLayoutContainer>
  );
};
