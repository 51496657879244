import React from 'react';
import { useSelector } from 'react-redux';

import { WithoutHeaderModal } from '@ams-package/modal';
import {
  SocialMediaButtons,
  SocialMediaButton,
  ContactInfoButtonsBlock,
} from '@ams-package/button';
import { EmailIcon } from '@ams-package/icons';
import { getManagerContacts } from '@ams-package/app';
import { Loader } from '@ams-package/loader';

import { OFFER_ACTIVATION_MODAL_NAME } from '../../consts';
import { isReadySelector } from '../../store';

import { Landings } from './Landings';
import {
  TabWrapper,
  ModalButtonsWrapper,
  ModalContentWrapper,
  ModalDescriptionWrapper,
} from './styled';

export const LinkAndLandingTab: React.FC = () => {
  const manager = useSelector(getManagerContacts);
  const isReady = useSelector(isReadySelector);

  // TODO add skeleton for new design
  if (!isReady) return <Loader center />;

  return (
    <TabWrapper>
      <WithoutHeaderModal name={OFFER_ACTIVATION_MODAL_NAME} appearance="fixed">
        <ModalContentWrapper>
          <ModalDescriptionWrapper>
            <h3>Offer activation</h3>
            <div>
              <div>Please contact the manager to activate</div>
              <div>the offer in a convenient way</div>
            </div>
          </ModalDescriptionWrapper>
          <ModalButtonsWrapper>
            {manager?.email && (
              <ContactInfoButtonsBlock numberOfButtons={1}>
                <SocialMediaButton
                  name="Email"
                  value={manager.email}
                  icon={<EmailIcon />}
                  appearance="lightSecondary"
                />
              </ContactInfoButtonsBlock>
            )}
            <SocialMediaButtons appearance="lightSecondary" />
          </ModalButtonsWrapper>
        </ModalContentWrapper>
      </WithoutHeaderModal>
      <Landings />
    </TabWrapper>
  );
};
