import React from 'react';

import { Button } from '@ams-package/button';
import { PathNames } from '@ams-package/router';

import { IOffer } from '../../../offers';

import {
  OfferName,
  TopOfferWrapper,
  IconWrapper,
  ChartIcon,
  OfferInfo,
  ButtonWrapper,
} from './styled';

export const TopOffer: React.FC<IOffer> = ({
  id,
  name,
  offerDealValue,
  offerDealType,
}) => (
  <TopOfferWrapper>
    <OfferName>
      {/* TODO render icon by link from BE instead mock */}
      <IconWrapper>
        <ChartIcon />
      </IconWrapper>
      <h4>{name}</h4>
    </OfferName>
    <OfferInfo>{offerDealType}</OfferInfo>
    <OfferInfo>{offerDealValue}</OfferInfo>
    <ButtonWrapper to={PathNames.offer.replace(':id', String(id))}>
      <Button baseAppearance="lightSecondary" appearance="moreDetails">
        More details
      </Button>
    </ButtonWrapper>
  </TopOfferWrapper>
);
