import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { classNameWithPrefix } from '@crm-framework/utils';

import { StatusTypes } from './types';

const blockName = 'payouts';

export const PayoutsRequestWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'request-wrapper'),
})`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: 7fr 5fr;
  grid-template-rows: 100%;
  gap: 16px;
`;

export const CalendarIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'calendar-icon-wrapper'),
})`
  margin-right: 6px;
  width: auto !important;
`;

export const StatusSnippet = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'status-snippet'),
})<{ status: StatusTypes }>`
  &::marker {
    padding-right: 4px;
  }
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  color: ${({ status }) => {
    switch (status) {
      case StatusTypes.Pending:
        return '#59A8D4';
      case StatusTypes.Confirmed:
      case StatusTypes.InProgress:
        return '#FFC555';
      case StatusTypes.WaitingForPayment:
      case StatusTypes.Approved:
      case StatusTypes.Paid:
        return '#4AA785';
      case StatusTypes.Rejected:
        return 'var(--danger-text-color)';
      default:
        return 'var(--white)';
    }
  }};
`;

export const AdditionalInfoWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'additional-info-wrapper'),
})`
  margin-top: 20px;
`;

export const PaymentMethodLinkWrapper = styled(NavLink).attrs({
  className: classNameWithPrefix(blockName, 'payment-method-link-wrapper'),
})`
  color: var(--default-text-color);
  display: flex;
  gap: 16px;
  border-bottom: 1px solid var(--light-grey);
  padding-bottom: 20px;

  svg {
    transform: rotate(-45deg);
  }
`;
