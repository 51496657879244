import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'styled-css-grid';

import Form, { FormField } from '@xcritical/forms';

import { InputFormField } from '@ams-package/input';
import { Label } from '@ams-package/label';
import { Select } from '@ams-package/select';

import {
  PERSONAL_DATA_REGISTER_FORM,
  PERSONAL_DATA_FORM_FIELDS,
  MESSENGER_TYPE,
  MESSENGER_TYPE_DICTIONARY,
} from '../consts';
import { messengerTypeSelector } from '../store';

export const PersonalDataForm: React.FC = () => {
  const messengerType = useSelector(messengerTypeSelector);
  const isWhatsApp = messengerType === MESSENGER_TYPE.WhatsApp;

  return (
    <Form name={PERSONAL_DATA_REGISTER_FORM}>
      <Grid columns="1fr" gap="20px">
        <div>
          <Label isRequired htmlFor={PERSONAL_DATA_FORM_FIELDS.firstname}>
            First Name
          </Label>
          <FormField
            id={PERSONAL_DATA_FORM_FIELDS.firstname}
            name={PERSONAL_DATA_FORM_FIELDS.firstname}
            shouldFitContainer
            component={InputFormField}
            isClearable
            placeholder="Input your first name"
          />
        </div>
        <div>
          <Label isRequired htmlFor={PERSONAL_DATA_FORM_FIELDS.lastname}>
            Last Name
          </Label>
          <FormField
            id={PERSONAL_DATA_FORM_FIELDS.lastname}
            name={PERSONAL_DATA_FORM_FIELDS.lastname}
            shouldFitContainer
            component={InputFormField}
            isClearable
            placeholder="Input your last name"
          />
        </div>
        <div>
          <Label isRequired htmlFor={PERSONAL_DATA_FORM_FIELDS.messengerType}>
            Where do you prefer to communicate?
          </Label>
          <FormField
            id={PERSONAL_DATA_FORM_FIELDS.messengerType}
            name={PERSONAL_DATA_FORM_FIELDS.messengerType}
            shouldFitContainer
            options={MESSENGER_TYPE_DICTIONARY}
            placeholder="Not selected"
            component={Select}
          />
        </div>
        {messengerType && (
          <div>
            <Label isRequired htmlFor={PERSONAL_DATA_FORM_FIELDS.contactValue}>
              {isWhatsApp ? 'Mobile number' : 'Your nickname'}
            </Label>
            <FormField
              id={PERSONAL_DATA_FORM_FIELDS.contactValue}
              name={PERSONAL_DATA_FORM_FIELDS.contactValue}
              shouldFitContainer
              component={InputFormField}
              isClearable
              placeholder={isWhatsApp ? 'Input mobile number' : '@nickname'}
            />
          </div>
        )}
      </Grid>
    </Form>
  );
};
