import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STORE_NAME } from '../consts';
import { ILoginState } from '../types';

export * from './selectors';
export const SIGN_IN = `${STORE_NAME}/sign-in`;
export const SIGN_UP = `${STORE_NAME}/sign-up`;
export const RESET_PASSWORD = `${STORE_NAME}/reset-password`;
export const RESTORE_PASSWORD = `${STORE_NAME}/restore-password`;
export const VALIDATE_REGISTER_FORM = `${STORE_NAME}/validate-register-form`;

export const initialState: ILoginState = {
  isLoading: false,
  isRegistrationInfoValidated: false,
  isPasswordReset: false,
  isPasswordRestored: false,
};

const loginStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsRegistrationInfoValidated: (state) => {
      state.isRegistrationInfoValidated = true;
    },
    setIsPasswordReset: (state, { payload }: PayloadAction<boolean>) => {
      state.isPasswordReset = payload;
    },
    setIsPasswordRestored: (state, { payload }: PayloadAction<boolean>) => {
      state.isPasswordRestored = payload;
    },
  },
});

export const loginActions = {
  ...loginStore.actions,
  signIn: createAction(SIGN_IN),
  resetPassword: createAction(RESET_PASSWORD),
  validateRegisterForm: createAction(VALIDATE_REGISTER_FORM),
  signUp: createAction(SIGN_UP),
  restorePassword: createAction(RESTORE_PASSWORD),
};

export const loginReducer = loginStore.reducer;
