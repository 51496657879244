import { takeLatest } from 'redux-saga/effects';

import { loginActions } from '../store';

import { handleSignIn, handleValidate, handleSignUp } from './sagas';
import {
  handleResetPassword,
  handleRestorePassword,
} from './resetPasswordSagas';

export function* watchLoginSagas() {
  yield takeLatest(loginActions.signIn, handleSignIn);
  yield takeLatest(loginActions.validateRegisterForm, handleValidate);
  yield takeLatest(loginActions.signUp, handleSignUp);
  yield takeLatest(loginActions.resetPassword, handleResetPassword);
  yield takeLatest(loginActions.restorePassword, handleRestorePassword);
}
