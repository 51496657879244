import { put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'typed-redux-saga';

import { xcriticalModalOpen } from '@xcritical/modal';

import { handleServerError } from '@ams-package/utils';

import { offersActions } from '../store';
import { ILandingPagesPayload, IOfferInitPayload } from '../types';
import { OFFER_ACTIVATION_MODAL_NAME } from '../consts';

import { getOffer, getLandingPages } from './api';

export function* handleOfferInit({
  payload,
}: PayloadAction<IOfferInitPayload>) {
  try {
    const { offerId } = payload;

    yield put(offersActions.setIsReady(false));

    const offerData = yield* call(getOffer, offerId);

    if (!(offerData.isActivated && offerData.isActive)) {
      yield put(xcriticalModalOpen(OFFER_ACTIVATION_MODAL_NAME));
    }

    yield put(offersActions.setSelectedOffer(offerData));
    yield put(offersActions.getLandingPages({ offerId }));
  } catch (error) {
    handleServerError({ error });
  } finally {
    yield put(offersActions.setIsReady(true));
  }
}

export function* handleGetLandingPages({
  payload,
}: PayloadAction<ILandingPagesPayload>) {
  try {
    const landingPages = yield* call(getLandingPages, payload.offerId);

    yield put(offersActions.setLandingPages(landingPages));
  } catch (error) {
    yield handleServerError({ error });
  }
}
