import { createSelector } from 'reselect';

import { formSelector } from '@xcritical/forms';

import { formatDateForServer } from '@ams-package/utils';

import { CHANGE_PASSWORD_FORM, PERSONAL_INFO_FORM } from '../consts';
import {
  IChangePasswordFormState,
  IPersonalInfoFormState,
  IPersonalInfoRequestModel,
} from '../types';

export const changePasswordFormSelector = (state): IChangePasswordFormState =>
  formSelector(state, CHANGE_PASSWORD_FORM);

export const personalInfoFormSelector = (state): IPersonalInfoFormState =>
  formSelector(state, PERSONAL_INFO_FORM);

export const personalInfoRequestModelSelector = createSelector(
  personalInfoFormSelector,
  ({ model }): IPersonalInfoRequestModel => ({
    ...model,
    country: model.country ? String(model.country) : '',
    birthday: model.birthday ? formatDateForServer(model.birthday) : '',
  })
);
