import React from 'react';
import { Grid } from 'styled-css-grid';

import Form, { FormField } from '@xcritical/forms';

import {
  PasswordFormField,
  PasswordValidationFormField,
} from '@ams-package/input';
import { Label } from '@ams-package/label';

import { RESTORE_PASSWORD_FORM, RESTORE_PASSWORD_FORM_FIELDS } from '../consts';

export const RestorePasswordForm: React.FC = () => (
  <Form name={RESTORE_PASSWORD_FORM}>
    <Grid columns="1fr" gap="20px">
      <div>
        <Label isRequired htmlFor={RESTORE_PASSWORD_FORM_FIELDS.password}>
          Password
        </Label>
        <FormField
          id={RESTORE_PASSWORD_FORM_FIELDS.password}
          name={RESTORE_PASSWORD_FORM_FIELDS.password}
          shouldFitContainer
          component={PasswordValidationFormField}
          isClearable
          placeholder="Enter password"
        />
      </div>
      <div>
        <Label
          isRequired
          htmlFor={RESTORE_PASSWORD_FORM_FIELDS.confirmPassword}>
          Confirm password
        </Label>
        <FormField
          id={RESTORE_PASSWORD_FORM_FIELDS.confirmPassword}
          name={RESTORE_PASSWORD_FORM_FIELDS.confirmPassword}
          shouldFitContainer
          component={PasswordFormField}
          isClearable
          placeholder="Enter password"
        />
      </div>
    </Grid>
  </Form>
);
