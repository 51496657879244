import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TabsWithNavigation } from '@ams-package/tabs';
import { svgComponents } from '@ams-package/icons';
import { PathNames } from '@ams-package/router';

import { createTabsConfig } from '../../../utils';
import { offersDataSelector } from '../store';

import { OffersTab } from './OffersTab';

const TAB_PATHS = {
  allOffers: `${PathNames.offers}?tabName=allOffers`,
  availableOffers: `${PathNames.offers}?tabName=availableOffers`,
  myOffers: `${PathNames.offers}?tabName=myOffers`,
};

export const OffersTabs: React.FC = () => {
  const {
    allOffersCount,
    myOffersCount,
    availableOffersCount,
    allOffers,
    availableOffers,
    myOffers,
  } = useSelector(offersDataSelector);

  const tabData = useMemo(
    () => [
      {
        TabIcon: svgComponents.AllOffersIcon,
        title: `All offers (${allOffersCount})`,
        Content: () => <OffersTab data={allOffers} order={0} />,
        key: 'allOffers',
        url: TAB_PATHS.allOffers,
      },
      {
        TabIcon: svgComponents.AvailableOffersIcon,
        title: `Available offers (${availableOffersCount})`,
        Content: () => <OffersTab data={availableOffers} order={1} />,
        key: 'availableOffers',
        url: TAB_PATHS.availableOffers,
      },
      {
        TabIcon: svgComponents.MyOffersIcon,
        title: `My offers (${myOffersCount})`,
        Content: () => <OffersTab data={myOffers} order={2} />,
        key: 'myOffers',
        url: TAB_PATHS.myOffers,
      },
    ],
    [
      allOffersCount,
      myOffersCount,
      availableOffersCount,
      allOffers,
      availableOffers,
      myOffers,
    ]
  );

  const tabPanels = useMemo(() => createTabsConfig(tabData), [tabData]);

  return tabPanels ? <TabsWithNavigation tabPanels={tabPanels} /> : <></>;
};
