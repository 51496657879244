import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@ams-package/button';

import { markdownOfferDataSelector } from '../../store';

import { ButtonsWrapper } from './styled';
import { AdvertiserContent } from './Advertiser';
import { MarkdownContent } from './MarkdownContent';

enum TAB_LIST_NAMES {
  advertiser = 'Advertiser',
  rates = 'Rates',
  rules = 'Rules',
}

const INFORMATION_TAB_NAMES = Object.values(TAB_LIST_NAMES);

export const InformationTab: React.FC = () => {
  const [currentName, setCurrentName] = useState<TAB_LIST_NAMES>(
    INFORMATION_TAB_NAMES[0]
  );
  const { rates, rules } = useSelector(markdownOfferDataSelector);

  const TAB_LIST_CONTENT = useMemo(
    () => ({
      [TAB_LIST_NAMES.advertiser]: <AdvertiserContent />,
      [TAB_LIST_NAMES.rates]: <MarkdownContent data={rates} />,
      [TAB_LIST_NAMES.rules]: <MarkdownContent data={rules} />,
    }),
    [rates, rules]
  );

  return (
    <>
      <ButtonsWrapper>
        {INFORMATION_TAB_NAMES.map((name) => (
          <Button
            appearance="secondary"
            onClick={() => setCurrentName(name)}
            selected={currentName === name}>
            {name}
          </Button>
        ))}
      </ButtonsWrapper>
      {TAB_LIST_CONTENT[currentName]}
    </>
  );
};
