import { put } from 'redux-saga/effects';

import { registryActions } from '@crm-framework/registry';

import { loadAndSaveDictionariesByName } from '@ams-package/dictionaries';

import { PAYMENT_SETTINGS_IS_LOADING } from '../consts';

export function* handleInitPaymentSettings(): Generator {
  try {
    yield put(registryActions.set(PAYMENT_SETTINGS_IS_LOADING, true));

    yield* loadAndSaveDictionariesByName(['paymentMethods', 'texts']);
  } catch (error) {
    console.error(error);
  } finally {
    yield put(registryActions.set(PAYMENT_SETTINGS_IS_LOADING, false));
  }
}
