import { getObjectWithoutUndefinedPropsFrom } from 'utilitify';

import {
  Conditions,
  validateIsRequired,
  validatePassword,
  validatePhone,
} from '@ams-package/utils';

import { IChangePasswordModel, IPersonalInfoRequestModel } from '../types';
import {
  CHANGE_PASSWORD_FORM_FIELDS,
  PERSONAL_INFO_FORM_FIELDS,
  PERSONAL_INFO_REQUIRED_FIELDS,
} from '../consts';

export const validateChangePasswordModel = (model: IChangePasswordModel) => {
  const errors = {};

  errors[CHANGE_PASSWORD_FORM_FIELDS.oldPassword] = validatePassword({
    value: model.oldPassword,
    message: {
      isValidMessage: 'Password is invalid',
    },
  });
  errors[CHANGE_PASSWORD_FORM_FIELDS.newPassword] = validatePassword({
    value: model.newPassword,
  });
  errors[CHANGE_PASSWORD_FORM_FIELDS.confirmPassword] = validateIsRequired({
    value: model.confirmPassword,
  });

  if (
    model.confirmPassword &&
    model.newPassword &&
    model.confirmPassword !== model.newPassword
  ) {
    errors[CHANGE_PASSWORD_FORM_FIELDS.confirmPassword] =
      "Passwords don't match";
  }

  return getObjectWithoutUndefinedPropsFrom(errors);
};

export const validatePersonalInfoModel = (model: IPersonalInfoRequestModel) => {
  const errors = {};

  Object.keys(model).forEach((key) => {
    if (PERSONAL_INFO_REQUIRED_FIELDS[key]) {
      errors[key] = validateIsRequired({ value: model[key] });
    }
  });

  errors[PERSONAL_INFO_FORM_FIELDS.phone] = validatePhone({
    value: model.phone,
  });

  errors[PERSONAL_INFO_FORM_FIELDS.viber] = validatePhone({
    value: model.viber,
    conditions: [Conditions.isPhone],
  });
  errors[PERSONAL_INFO_FORM_FIELDS.whatsApp] = validatePhone({
    value: model.whatsApp,
    conditions: [Conditions.isPhone],
  });

  return getObjectWithoutUndefinedPropsFrom(errors);
};
