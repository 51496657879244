import React from 'react';
import { useSelector } from 'react-redux';

import { PathNames } from '@ams-package/router';

import { Link } from '../../styled';
import { topOffersSelector } from '../../store';

import { TopOffersCard, HeaderWrapper, ListWrapper } from './styled';
import { TopOffer } from './TopOffer';

export const TopOffers: React.FC = () => {
  const offers = useSelector(topOffersSelector);

  if (!offers.length) return <></>;

  return (
    <TopOffersCard>
      <HeaderWrapper>
        <h3>Top offers</h3>
        <Link to={PathNames.offers}>View all offers</Link>
      </HeaderWrapper>
      <ListWrapper>
        {offers.map((offer) => (
          <TopOffer key={offer.id} {...offer} />
        ))}
      </ListWrapper>
    </TopOffersCard>
  );
};
