import React from 'react';

import { DashboardSettingsType } from '@ams-package/dashboard';
import { FilterTypes } from '@ams-package/filters';
import { BaseCell } from '@ams-package/grid';
import { svgComponents } from '@ams-package/icons';
import { toCurrency } from '@ams-package/utils';

import { CalendarIconWrapper } from './styled';

export const settings: DashboardSettingsType[] = [
  {
    name: 'groupKey',
    field: 'group',
    displayName: 'Group By',
    inGrid: false,
    inFilter: true,
    replaceDisplayNameToSelectedValue: true,
    replaceGridColumn: 'date',
    filterType: FilterTypes.SingleSelect,
    inSort: true,
    defaultVal: 'day',
    prefix: 'Group by',
    isClearable: false,
    options: [
      // { label: 'Platform', value: 'platform' },
      { label: 'Country', value: 'country' },
      { label: 'Brand', value: 'brand' },
      { label: 'Offer', value: 'offer' },
      { label: 'Month', value: 'month' },
      { label: 'Week', value: 'week' },
      { label: 'Day', value: 'day' },
    ],
  },
  {
    name: 'campaign',
    displayName: 'campaign',
    inGrid: false,
    isHidden: true,
    inFilter: true,
    filterType: FilterTypes.SingleSelect,
  },
  {
    name: 'date',
    displayName: 'Date',
    filterType: FilterTypes.Date,
    inGrid: true,
    inFilter: true,
    inSort: true,
    render: (value: string) => (
      <BaseCell>
        <CalendarIconWrapper>
          <svgComponents.Calendar />
        </CalendarIconWrapper>
        {new Date(value).toLocaleDateString()}
      </BaseCell>
    ),
  },
  {
    name: 'country',
    displayName: 'Country',
    filterType: FilterTypes.MultiSelect,
    dictionaryName: 'country',
    inGrid: false,
    inFilter: true,
  },
  {
    name: 'deviceType',
    dictionaryName: 'deviceType',
    displayName: 'Device type',
    filterType: FilterTypes.MultiSelect,
    inGrid: false,
    inFilter: true,
  },
  {
    name: 'platform',
    displayName: 'Platform',
    dictionaryName: 'devicePlatform',
    filterType: FilterTypes.MultiSelect,
    inGrid: false,
    inFilter: false,
  },
  {
    name: 'hits',
    displayName: 'Hits',
    inGrid: true,
    inFilter: false,
    inSort: true,
  },
  {
    name: 'uniques',
    displayName: 'Uniques',
    inGrid: true,
    inFilter: false,
    inSort: true,
  },
  {
    name: 'regs',
    displayName: 'Regs',
    inGrid: true,
    inFilter: false,
    inSort: true,
  },
  {
    name: 'ftd',
    displayName: 'FTD',
    inGrid: true,
    inFilter: false,
    inSort: true,
  },
  {
    name: 'deposits',
    displayName: 'Deposits',
    inGrid: true,
    inFilter: false,
    inSort: true,
  },
  {
    name: 'profit',
    displayName: 'Profit',
    inGrid: true,
    inFilter: false,
    inSort: true,
    render: (value: string) => <BaseCell>{`$ ${toCurrency(value)}`}</BaseCell>,
  },
];
