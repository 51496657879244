import { call, select } from 'typed-redux-saga';
import { put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import {
  xcriticalFormError,
  xcriticalFormInit,
  xcriticalFormShowErrors,
} from '@xcritical/forms';
import { xcriticalModalOpen } from '@xcritical/modal';

import { registryActions } from '@crm-framework/registry';

import { loadAndSaveDictionariesByName } from '@ams-package/dictionaries';
import { getUser, handleGetUser } from '@ams-package/app';
import { handleServerError } from '@ams-package/utils';
import { StatusModalTypes } from '@ams-package/status-message';

import {
  ACCOUNT_SETTINGS_STATUS_MODAL_NAME,
  PERSONAL_INFO_FORM,
  PERSONAL_INFO_IS_LOADING,
  PERSONAL_INFO_IS_READY,
} from '../consts';
import { personalInfoRequestModelSelector } from '../store';

import { validatePersonalInfoModel } from './validation';
import { savePersonalInfo } from './api';

export function* handleInitPersonalInfo(): Generator {
  try {
    yield* loadAndSaveDictionariesByName(['country']);
    const currentUser = yield* select(getUser);

    if (currentUser) {
      const contacts = currentUser.contacts || {};
      yield put(
        xcriticalFormInit(PERSONAL_INFO_FORM, {
          ...contacts,
          ...currentUser,
          birthday: currentUser.birthday
            ? new Date(currentUser.birthday)
            : null,
        })
      );
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(registryActions.set(PERSONAL_INFO_IS_READY, true));
  }
}

export function* handleChangePersonalInfo(): Generator {
  try {
    yield put(registryActions.set(PERSONAL_INFO_IS_LOADING, true));

    const requestModel = yield* select(personalInfoRequestModelSelector);
    const validationErrors = validatePersonalInfoModel(requestModel);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(PERSONAL_INFO_FORM, true));
      yield put(xcriticalFormError(PERSONAL_INFO_FORM, validationErrors));

      return;
    }

    yield* call(savePersonalInfo, requestModel);
    yield* call(handleGetUser);

    yield put(
      xcriticalModalOpen(ACCOUNT_SETTINGS_STATUS_MODAL_NAME, {
        message: {
          type: StatusModalTypes.Success,
          title: 'Your data has been successfully saved',
        },
      })
    );
  } catch (error) {
    yield call(handleServerError, {
      error,
      formName: PERSONAL_INFO_FORM,
      statusModalName: ACCOUNT_SETTINGS_STATUS_MODAL_NAME,
    });
  } finally {
    yield put(registryActions.set(PERSONAL_INFO_IS_LOADING, false));
  }
}
