import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'offers-tabs';

export const EmptyOffersWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'empty-offers-wrapper'),
})`
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyOffersContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'empty-offers-content-wrapper'),
})`
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const EmptyOffersTextWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'empty-offers-text-wrapper'),
})`
  color: var(--hover-secondary-color);
`;
