import { reducer as formReducer } from '@xcritical/forms';
import { modalReducer } from '@xcritical/modal';

import { createStore } from '@crm-framework/saga-injector';
import { registryReducer } from '@crm-framework/registry';
import { dictionariesReducer } from '@crm-framework/dictionaries';

import { appReducer } from '@ams-package/app';
import { filtersReducer } from '@ams-package/filters';

import { loginReducer } from './pages/login';
import { offersReducer } from './pages/offers';
import { mainDashboardReducer } from './pages/dashboard';

export const store = createStore(
  {
    form: formReducer,
    modal: modalReducer,
    login: loginReducer,
    app: appReducer,
    offers: offersReducer,
    registry: registryReducer,
    filters: filtersReducer,
    dictionaries: dictionariesReducer,
    mainDashboard: mainDashboardReducer,
  },
  {}
);
