import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { IUserBalanceModel } from '@ams-package/app';

import { IPaymentRequestState } from './types';
const initialState: IPaymentRequestState = {
  amount: 0,
  dateTime: '',
  isReady: false,
};
export const PAYMENT_REQUEST_FORM = 'PAYMENT_REQUEST_FORM';
export const STORE_NAME = '@payment-request';
const paymentRequestStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    finishInit: {
      reducer: (_, { payload }: PayloadAction<IUserBalanceModel>) => ({
        ...payload,
        isReady: true,
      }),
      prepare: (balance: IUserBalanceModel) => ({
        payload: balance,
      }),
    },
  },
});

const INITIALIZE = `${STORE_NAME}/initialize` as const;
const SUBMIT = `${STORE_NAME}/submit` as const;

export const paymentRequestActions = {
  ...paymentRequestStore.actions,
  init: createAction(INITIALIZE),
  submit: createAction(SUBMIT),
};

export const paymentRequestReducer = paymentRequestStore.reducer;
