import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SideModal } from '@ams-package/side-modal';
import { getAppConfig } from '@ams-package/app';
import { Button } from '@ams-package/button';
import { useKeyPress } from '@ams-package/utils';

import {
  SignInForm,
  DescriptionWrapper,
  BottomDescriptionWrapper,
  BottomContentWrapper,
  SideModalTitleWrapper,
} from './components';
import { loginActions } from './store';
import { LoginPageWrapper, LoginHeader } from './styled';
import { HEADER } from './consts';

export const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const config = useSelector(getAppConfig);

  const handlerSignIn = useCallback(() => {
    dispatch(loginActions.signIn());
  }, []);
  // TODO handle case when we have few forms on the page
  useKeyPress('Enter', handlerSignIn);

  return (
    <>
      <LoginHeader>{HEADER}</LoginHeader>
      <LoginPageWrapper>
        <SideModal
          isOpen
          title={<SideModalTitleWrapper>Sign in</SideModalTitleWrapper>}
          appearance="login"
          withCloseButton={false}>
          <>
            <DescriptionWrapper>
              Enter your email address and password to login
            </DescriptionWrapper>
            <SignInForm canResetPassword={config?.ResetPassword ?? true} />
            <BottomContentWrapper>
              <Button shouldFitContent onClick={handlerSignIn}>
                Sign In
              </Button>
              <BottomDescriptionWrapper>
                Don&apos;t have an account yet?{' '}
                <a
                  href={`${window.location.origin}/registration`}
                  target="_blank"
                  rel="noreferrer">
                  Sign up
                </a>
              </BottomDescriptionWrapper>
            </BottomContentWrapper>
          </>
        </SideModal>
      </LoginPageWrapper>
    </>
  );
};
