import { createSelector } from 'reselect';

import { getAffiliateId } from '@ams-package/app';

import { STORE_NAME } from '../consts';
import { IOffer, ILandingPage, IAdvertiser } from '../types';

export const offersSelector = (state) => state[STORE_NAME];

export const myOffersSelector = (state): IOffer[] =>
  state[STORE_NAME].myOffersData;

const myOffersCountSelector = (state): number =>
  state[STORE_NAME].myOffersDataCount;

export const allOffersSelector = (state): IOffer[] =>
  state[STORE_NAME].allOffersData;

const allOffersCountSelector = (state): number =>
  state[STORE_NAME].allOffersDataCount;

export const availableOffersSelector = (state): IOffer[] =>
  state[STORE_NAME].availableOffersData;

const availableOffersCountSelector = (state): number =>
  state[STORE_NAME].availableOffersDataCount;

export const isReadySelector = (state): boolean => state[STORE_NAME].isReady;

export const offersDataSelector = createSelector(
  allOffersCountSelector,
  myOffersCountSelector,
  availableOffersCountSelector,
  allOffersSelector,
  myOffersSelector,
  availableOffersSelector,
  (
    allOffersCount,
    myOffersCount,
    availableOffersCount,
    allOffers,
    myOffers,
    availableOffers
  ) => ({
    allOffersCount,
    myOffersCount,
    availableOffersCount,
    allOffers,
    myOffers,
    availableOffers,
  })
);

// offer
export const offerLinksSelector = (state): string =>
  state[STORE_NAME].offerData?.links?.mixedTraffic;

export const offerNameSelector = (state): string =>
  state[STORE_NAME].offerData?.name ?? '';

export const landingPageDisabledSelector = (state): boolean =>
  !state[STORE_NAME].offerData?.isActivated;

export const allOfferLandingsSelector = (state): ILandingPage[] | null =>
  state[STORE_NAME].landingPages;

export const offerLandingsSelector = createSelector(
  allOfferLandingsSelector,
  landingPageDisabledSelector,
  (landingPages, isDisabled): ILandingPage[] | null => {
    if (!landingPages) return null;

    return isDisabled
      ? landingPages
      : landingPages.filter((lp) => lp.isActivated);
  }
);

export const lastCopiedLinkSelector = (state): string | null =>
  state[STORE_NAME].lastCopiedLink;

export const offersLinkQuerySelector = createSelector(
  offerLinksSelector,
  getAffiliateId,
  landingPageDisabledSelector,
  lastCopiedLinkSelector,
  (url, affId, isDisabled, lastCopiedLink) => ({
    url,
    affId,
    isDisabled,
    lastCopiedLink,
  })
);

// info
export const markdownOfferDataSelector = (
  state
): { rates?: string; rules?: string } => ({
  rates: state[STORE_NAME].offerData?.rates,
  rules: state[STORE_NAME].offerData?.rules,
});

export const advertiserSelector = (state): IAdvertiser | undefined => {
  const ad: IAdvertiser | undefined = state[STORE_NAME].offerData?.advertiser;

  if (!ad) return undefined;

  return Object.keys(ad).reduce(
    (res, key) => ({
      ...res,
      [key]: typeof ad[key] === 'string' ? ad[key] : ad[key].join(', '),
    }),
    ad
  );
};
