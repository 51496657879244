import React from 'react';
import { Outlet } from 'react-router-dom';

import { AuthLayoutContainer } from './styles';

export const AuthLayout: React.FC = () => (
  <AuthLayoutContainer>
    <Outlet />
  </AuthLayoutContainer>
);
