import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from 'styled-css-grid';
import { LockCircle } from 'iconsax-react';

import Form, { FormField } from '@xcritical/forms';

import { useRegistry } from '@crm-framework/registry';

import { Label } from '@ams-package/label';
import { InputFormField } from '@ams-package/input';
import { Button } from '@ams-package/button';
import { DictionarySelect } from '@ams-package/select';
import { DatePickerFormField } from '@ams-package/datepicker';
import {
  getEighteenYearsAgoDate,
  MAX_PHONE_LENGTH,
  PHONE_VALIDATION_PATTERN,
} from '@ams-package/utils';

import {
  PERSONAL_INFO_FORM,
  PERSONAL_INFO_FORM_FIELDS,
  PERSONAL_INFO_GRID_SETTINGS,
  PERSONAL_INFO_IS_LOADING,
  PERSONAL_INFO_IS_READY,
  PERSONAL_INFO_REQUIRED_FIELDS,
} from '../consts';
import { accountSettingsActions } from '../store';

import { SubmitButtonWrapper } from './styled';
import { PersonalInfoTabSkeleton } from './_PersonalInfoTabSkeleton';

export const PersonalInfoTab: React.FC = () => {
  const dispatch = useDispatch();
  const isReady = useRegistry<boolean>(PERSONAL_INFO_IS_READY, false);
  const isLoading = useRegistry<boolean>(PERSONAL_INFO_IS_LOADING, false);

  useEffect(() => {
    dispatch(accountSettingsActions.initPersonalInfo());
  }, []);

  const onChangePersonalInfo = useCallback(() => {
    dispatch(accountSettingsActions.savePersonalInfo());
  }, []);

  if (!isReady) return <PersonalInfoTabSkeleton />;

  return (
    <Form name={PERSONAL_INFO_FORM}>
      <Grid {...PERSONAL_INFO_GRID_SETTINGS}>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.email}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.email}>
            Email address
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.email}
            name={PERSONAL_INFO_FORM_FIELDS.email}
            shouldFitContainer
            disabled
            postfix={<LockCircle size={20} />}
            component={InputFormField}
            placeholder="Enter email address"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.firstname}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.firstname}>
            First Name
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.firstname}
            name={PERSONAL_INFO_FORM_FIELDS.firstname}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter first name"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.lastname}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.lastname}>
            Last Name
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.lastname}
            name={PERSONAL_INFO_FORM_FIELDS.lastname}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter last name"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.phone}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.phone}>
            Phone
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.phone}
            name={PERSONAL_INFO_FORM_FIELDS.phone}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter mobile number"
            pattern={PHONE_VALIDATION_PATTERN}
            maxLength={MAX_PHONE_LENGTH}
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.birthday}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.birthday}>
            Date of Birth
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.birthday}
            name={PERSONAL_INFO_FORM_FIELDS.birthday}
            component={DatePickerFormField}
            maxDate={getEighteenYearsAgoDate()}
            placeholder="Enter date of birth"
            disabled={isLoading}
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.country}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.country}>
            Country
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.country}
            name={PERSONAL_INFO_FORM_FIELDS.country}
            shouldFitContainer
            dictionaryName="country"
            component={DictionarySelect}
            isSearchable
            disabled={isLoading}
            placeholder="Not selected"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.whatsApp}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.whatsApp}>
            WhatsApp
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.whatsApp}
            name={PERSONAL_INFO_FORM_FIELDS.whatsApp}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter mobile number"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.viber}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.viber}>
            Viber
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.viber}
            name={PERSONAL_INFO_FORM_FIELDS.viber}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter mobile number"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.telegram}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.telegram}>
            Telegram
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.telegram}
            name={PERSONAL_INFO_FORM_FIELDS.telegram}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter your nickname"
          />
        </div>
        <div>
          <Label
            isRequired={PERSONAL_INFO_REQUIRED_FIELDS.skype}
            htmlFor={PERSONAL_INFO_FORM_FIELDS.skype}>
            Skype
          </Label>
          <FormField
            id={PERSONAL_INFO_FORM_FIELDS.skype}
            name={PERSONAL_INFO_FORM_FIELDS.skype}
            shouldFitContainer
            isClearable
            disabled={isLoading}
            component={InputFormField}
            placeholder="Enter your nickname"
          />
        </div>
        <SubmitButtonWrapper>
          <Button
            shouldFitContent
            onClick={onChangePersonalInfo}
            disabled={isLoading}>
            Save
          </Button>
        </SubmitButtonWrapper>
      </Grid>
    </Form>
  );
};
