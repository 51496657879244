import { authorizedWretch, formatDateForServer } from '@ams-package/utils';
import { parseUniversalFilters } from '@ams-package/filters';
import {
  APIGetItemsCallbackResponseType,
  IDashboardGridControllers,
} from '@ams-package/dashboard';

import { IStatisticFilters, IStatisticRequestModel } from './types';
import { UNIVERSAL_FILTERS } from './consts';

export const getAffiliatesStatistics = async (
  payload: IStatisticRequestModel
): Promise<APIGetItemsCallbackResponseType> =>
  authorizedWretch('/api/affiliates-offers-statistics').post(payload).json();

export const getStatistic = async (
  $filters: IStatisticFilters,
  { sorting, pageSize, page }: IDashboardGridControllers
): Promise<APIGetItemsCallbackResponseType> => {
  const { platform, date, groupKey, country, deviceType, campaign } =
    $filters || {};
  const filters = parseUniversalFilters(UNIVERSAL_FILTERS, {
    country,
    platform,
    deviceType,
    campaign,
  });
  const [dateFrom, dateTo] = date || [];

  const requestModel: IStatisticRequestModel = {
    filters,
    sorting: sorting ? [sorting] : undefined,
    dateFrom: dateFrom ? formatDateForServer(dateFrom) : undefined,
    dateTo: dateTo ? formatDateForServer(dateTo) : undefined,
    groupKey: groupKey ? String(groupKey) : undefined,
    limit: pageSize,
    offset: (page - 1) * pageSize,
  };

  return getAffiliatesStatistics(requestModel);
};
