import styled from 'styled-components';
import { Chart } from 'iconsax-react';
import { NavLink } from 'react-router-dom';

import { classNameWithPrefix } from '@crm-framework/utils';

import Panel from '@ams-package/panel';
import { generateFontStyle } from '@ams-package/theme';

const blockName = 'top-offers';

export const TopOffersCard = styled(Panel)`
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: column;
  gap: 4px;
`;

export const HeaderWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header-wrapper'),
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

export const ListWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'list-wrapper'),
})`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopOfferWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'offer-wrapper'),
})`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  column-gap: 8px;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--secondary-border-color);

  &:last-child {
    border: none;
    margin-bottom: 0;
  }
`;

export const OfferName = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'offer-name'),
})`
  display: flex;
  align-items: center;
  gap: 12px;

  h4 {
    word-break: break-word;
  }
`;

export const IconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'icon-wrapper'),
})`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: var(--orange);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferInfo = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'offer-info'),
})`
  ${generateFontStyle('body-1m')}
  color: var(--secondary-light-grey);
  word-break: break-word;
`;

export const ChartIcon = styled(Chart).attrs({
  className: classNameWithPrefix(blockName, 'chart-icon'),
})`
  width: 24px;
  height: 24px;
  fill: var(--base-white);
  stroke: var(--base-white);
`;

export const ButtonWrapper = styled(NavLink).attrs({
  className: classNameWithPrefix(blockName, 'button-wrapper'),
})`
  margin-left: auto;
`;
