import { getObjectWithoutUndefinedPropsFrom } from 'utilitify';

import {
  validateEmail,
  validateIsRequired,
  validatePassword,
} from '@ams-package/utils';

import {
  IPersonalDataModel,
  IRestorePasswordModel,
  ISignInModel,
} from '../types';
import {
  SIGN_IN_FORM_FIELDS,
  PERSONAL_DATA_FORM_FIELDS,
  RESTORE_PASSWORD_FORM_FIELDS,
} from '../consts';

export const validateSignInModel = (model: ISignInModel) => {
  const errors = {};

  errors[SIGN_IN_FORM_FIELDS.email] = validateEmail({ value: model.email });
  errors[SIGN_IN_FORM_FIELDS.password] = validateIsRequired({
    value: model.password,
  });

  return getObjectWithoutUndefinedPropsFrom(errors);
};

export const validatePersonalDataModel = (model: IPersonalDataModel) => {
  const errors = Object.keys(PERSONAL_DATA_FORM_FIELDS).reduce((err, key) => {
    err[key] = validateIsRequired({ value: model[key] });

    return err;
  }, {});

  return getObjectWithoutUndefinedPropsFrom(errors);
};

export const validateResetPasswordModel = (model: ISignInModel) => {
  const errors = {};

  errors[SIGN_IN_FORM_FIELDS.email] = validateIsRequired({
    value: model.email,
  });

  return getObjectWithoutUndefinedPropsFrom(errors);
};

export const validateRestorePasswordModel = (model: IRestorePasswordModel) => {
  const errors = {};

  errors[RESTORE_PASSWORD_FORM_FIELDS.password] = validatePassword({
    value: model.password,
  });
  errors[RESTORE_PASSWORD_FORM_FIELDS.confirmPassword] = validateIsRequired({
    value: model.confirmPassword,
  });

  if (
    model.confirmPassword &&
    model.password &&
    model.confirmPassword !== model.password
  ) {
    errors[RESTORE_PASSWORD_FORM_FIELDS.confirmPassword] =
      "Passwords don't match";
  }

  return getObjectWithoutUndefinedPropsFrom(errors);
};
