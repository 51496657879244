import { authorizedWretch } from '@ams-package/utils';

import { IOffer, IOffersRequest, IOfferData, ILandingPage } from '../types';

export const getOffers = async (payload: IOffersRequest): Promise<IOffer[]> =>
  authorizedWretch('/api/offers/list').post(payload).json();

export const getOffer = async (id: string): Promise<IOfferData> =>
  authorizedWretch(`/api/offers/${id}`).get().json();

export const getLandingPages = async (id: string): Promise<ILandingPage[]> =>
  authorizedWretch(`/api/offers/${id}/linked-landing-pages`).get().json();
