import URL from 'url-parse';

export const createUrlObject = (urlString = window.location.href): URL<any> =>
  new URL(urlString, true);

export const redirect = () => {
  const { query } = createUrlObject();

  if (query.returnUrl) {
    const decodedReturnUrl = decodeURIComponent(query.returnUrl);
    window.location.replace(decodedReturnUrl);
  } else {
    window.location.replace('/');
  }
};
