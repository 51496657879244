import React from 'react';

import { ReduxInject } from '@crm-framework/saga-injector';

import { STORE_NAME, regularPayoutReducer } from './store';
import { payoutDetailsWatchers, watchers } from './sagas';
import { RegularPayout } from './RegularPayout';
import { PayoutDate } from './PayoutDate';
export { RegularRequestStub } from './RegularRequestStub';
export { payoutDateSelector } from './selectors';
export { REGULAR_PAYOUT_STATUS_MESSAGES } from './consts';

export const RegularPayoutInject = ({ ...props }) => (
  <ReduxInject
    keyName={STORE_NAME}
    saga={watchers}
    sagaKey="regularPayouts"
    reducer={regularPayoutReducer}>
    <RegularPayout {...props} />
  </ReduxInject>
);

export default RegularPayoutInject;

export const PayoutDateInject = ({ ...props }) => (
  <ReduxInject
    keyName={STORE_NAME}
    saga={payoutDetailsWatchers}
    reducer={regularPayoutReducer}>
    <PayoutDate {...props} />
  </ReduxInject>
);
