import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'header';

export const HeaderSection = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'section'),
})`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: var(--header-section-padding);
  background-color: var(--background-color);
  z-index: 2;
`;

export const HeaderWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: 3fr 9fr;
  grid-template-rows: 100%;
  gap: 0px 0px;
  align-items: center;
  border-radius: 16px;
  background: var(--modal-background-color);
  padding: 8px 8px 8px 20px;
`;

export const HeaderInfoWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'info-wrapper'),
})`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 32px;
`;

export const HeaderButtonContent = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'button-content'),
})`
  display: flex;
  align-items: center;
  ${generateFontStyle('body-1m')}
  gap: 20px;
`;
