import React from 'react';

import { Button } from '@ams-package/button';

import {
  EmptyOffersContentWrapper,
  EmptyOffersWrapper,
  EmptyOffersTextWrapper,
} from './styled';

export const EmptyOffers: React.FC = () => (
  <EmptyOffersWrapper>
    <EmptyOffersContentWrapper>
      <EmptyOffersTextWrapper>
        You have no accepted offers yet
      </EmptyOffersTextWrapper>
      <Button
        href="/offers?tabName=availableOffers"
        appearance="active"
        shouldFitContent>
        Choose available offers
      </Button>
    </EmptyOffersContentWrapper>
  </EmptyOffersWrapper>
);
