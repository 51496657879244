export const STORE_NAME = 'login';

export const HEADER = 'AffShore';
export const SIGN_IN_FORM = 'loginForm';
export const PERSONAL_DATA_REGISTER_FORM = 'personalDataForm';
export const RESET_PASSWORD_FORM = 'resetPasswordForm';
export const RESTORE_PASSWORD_FORM = 'restorePasswordForm';

export const SIGN_IN_FORM_FIELDS = {
  email: 'email',
  password: 'password',
  isTermsAccepted: 'isTermsAccepted',
};

export const RESTORE_PASSWORD_FORM_FIELDS = {
  password: 'password',
  confirmPassword: 'confirmPassword',
};

export const RESET_PASSWORD_FORM_FIELDS = {
  email: 'email',
};

export const PERSONAL_DATA_FORM_FIELDS = {
  firstname: 'firstname',
  lastname: 'lastname',
  messengerType: 'messengerType',
  contactValue: 'contactValue',
};

export enum MESSENGER_TYPE {
  WhatsApp = 'WhatsApp',
  Telegram = 'Telegram',
  Skype = 'Skype',
}
export const MESSENGER_TYPE_DICTIONARY = ['WhatsApp', 'Telegram', 'Skype'].map(
  (type) => ({ id: type, label: type, value: type })
);
