import { DollarCircle, TagUser, WalletMoney, People } from 'iconsax-react';
import {
  subDays,
  subMonths,
  subWeeks,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  format,
} from 'date-fns';

import { FTDIcon } from '@ams-package/icons';
import { toCurrency } from '@ams-package/utils';

const DATE_FORMAT = 'yyyy/MM/dd';

export const statisticPeriods = {
  day: {
    name: 'day',
    title: 'Today',
    start: format(startOfDay(new Date()), DATE_FORMAT),
    end: format(new Date(), DATE_FORMAT),
    startPrevious: format(startOfDay(subDays(new Date(), 1)), DATE_FORMAT),
    endPrevious: format(endOfDay(subDays(new Date(), 1)), DATE_FORMAT),
  },
  week: {
    name: 'week',
    title: 'Week',
    start: format(startOfWeek(new Date()), DATE_FORMAT),
    end: format(new Date(), DATE_FORMAT),
    startPrevious: format(startOfWeek(subWeeks(new Date(), 1)), DATE_FORMAT),
    endPrevious: format(endOfWeek(subWeeks(new Date(), 1)), DATE_FORMAT),
  },
  month: {
    name: 'month',
    title: 'Month',
    start: format(startOfMonth(new Date()), DATE_FORMAT),
    end: format(new Date(), DATE_FORMAT),
    startPrevious: format(startOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
    endPrevious: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT),
  },
  threeMonths: {
    name: 'threeMonths',
    title: '3 Months',
    start: format(startOfMonth(subMonths(new Date(), 2)), DATE_FORMAT),
    end: format(new Date(), DATE_FORMAT),
    startPrevious: format(startOfMonth(subMonths(new Date(), 5)), DATE_FORMAT),
    endPrevious: format(endOfMonth(subMonths(new Date(), 3)), DATE_FORMAT),
  },
  allTime: {
    name: 'allTime',
    title: 'All Time',
  },
};

export enum StatisticFields {
  regs = 'regs',
  ftd = 'ftd',
  deposits = 'deposits',
  profit = 'profit',
  hits = 'hits',
}

export const statisticBlocks = [
  {
    name: StatisticFields.regs,
    title: 'Registrations',
    Icon: TagUser,
  },
  {
    name: StatisticFields.ftd,
    title: 'FTD',
    Icon: FTDIcon,
  },
  {
    name: StatisticFields.deposits,
    title: 'Deposits',
    Icon: WalletMoney,
  },
  {
    name: StatisticFields.profit,
    title: 'Profit',
    Icon: DollarCircle,
    render: (value?: string | number): string => toCurrency(value),
  },
  {
    name: StatisticFields.hits,
    title: 'Hits',
    Icon: People,
  },
];
