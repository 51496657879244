import React from 'react';

import { colors } from '@ams-package/theme';
import { Skeleton } from '@ams-package/skeleton';

import {
  StatisticBlockWrapper,
  IconWrapper,
  InfoWrapper,
  StatisticValue,
} from './styled';
import { StatisticBlockPropsType } from './StatisticBlock';

export const StatisticBlockSkeleton: React.FC<
  Omit<StatisticBlockPropsType, 'value' | 'percent'>
> = ({ title, Icon }) => (
  <StatisticBlockWrapper>
    <IconWrapper>
      <Icon color={colors.PRIMARY_COLOR} />
    </IconWrapper>
    <InfoWrapper>
      {title}
      <StatisticValue>
        <Skeleton
          width={50}
          height="var(--h3-font-size)"
          color="var(--background-color)"
        />
      </StatisticValue>
    </InfoWrapper>
  </StatisticBlockWrapper>
);
