import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Grid } from 'styled-css-grid';

import Form, { FormField } from '@xcritical/forms';

import {
  PasswordFormField,
  InputFormField,
  PasswordValidationFormField,
} from '@ams-package/input';
import { Label } from '@ams-package/label';
import { CheckboxFormField } from '@ams-package/checkbox';
import { getAppConfig } from '@ams-package/app';

import { SIGN_IN_FORM, SIGN_IN_FORM_FIELDS } from '../consts';
import {
  ForgotPasswordLinkWrapper,
  LabelWithLinkWrapper,
  TermsAndConditionsWrapper,
} from '../styled';

import { CheckboxWrapper } from './styled';

export const SignInForm: React.FC<{
  canResetPassword?: boolean;
  isSignUp?: boolean;
}> = ({ canResetPassword = false, isSignUp = false }) => {
  const config = useSelector(getAppConfig);
  const navigate = useNavigate();

  const goToReset = () => {
    navigate('/reset-password');
  };

  return (
    <Form name={SIGN_IN_FORM}>
      <Grid columns="1fr" gap="20px">
        <div>
          <Label isRequired htmlFor={SIGN_IN_FORM_FIELDS.email}>
            Email address
          </Label>
          <FormField
            id={SIGN_IN_FORM_FIELDS.email}
            name={SIGN_IN_FORM_FIELDS.email}
            shouldFitContainer
            component={InputFormField}
            isClearable
            placeholder="Enter email"
          />
        </div>
        <div>
          <LabelWithLinkWrapper>
            <Label isRequired htmlFor={SIGN_IN_FORM_FIELDS.password}>
              Password
            </Label>
            {canResetPassword && (
              <ForgotPasswordLinkWrapper onClick={goToReset}>
                Forgot Password?
              </ForgotPasswordLinkWrapper>
            )}
          </LabelWithLinkWrapper>
          <FormField
            id={SIGN_IN_FORM_FIELDS.password}
            name={SIGN_IN_FORM_FIELDS.password}
            shouldFitContainer
            component={
              isSignUp ? PasswordValidationFormField : PasswordFormField
            }
            isClearable
            placeholder="Enter password"
          />
        </div>
        {isSignUp && (
          <CheckboxWrapper>
            <FormField
              name={SIGN_IN_FORM_FIELDS.isTermsAccepted}
              component={CheckboxFormField}
            />
            I have read and agreed to the
            <TermsAndConditionsWrapper
              href={config?.TermsAndConditionsLink}
              target="_blank">
              Terms and Conditions
            </TermsAndConditionsWrapper>
          </CheckboxWrapper>
        )}
      </Grid>
    </Form>
  );
};
