import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';

import { STORE_NAME } from '../consts';
import {
  AffStatisticsType,
  IDashboardConfig,
  IMainDashboardState,
} from '../types';
import { IOffer } from '../../offers';

export * from './selectors';

export const initialState: IMainDashboardState = {
  affStatistics: null,
  isReady: false,
  topOffers: [],
  config: null,
};

const mainDashboardStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    setAffStatistics: (
      state,
      { payload }: PayloadAction<AffStatisticsType | null>
    ) => {
      state.affStatistics = payload;
    },
    setTopOffers: (state, { payload }: PayloadAction<IOffer[]>) => {
      state.topOffers = payload;
    },
    setIsReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isReady = payload;
    },
    setConfig: (state, { payload }: PayloadAction<IDashboardConfig>) => {
      state.config = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAffStatistics: (state, _action: PayloadAction<number | string>) => {
      state.affStatistics = null;
    },
  },
});

const MAIN_DASHBOARD_INIT = `${STORE_NAME}/init`;

export const mainDashboardActions = {
  ...mainDashboardStore.actions,
  init: createAction(MAIN_DASHBOARD_INIT),
};

export const mainDashboardReducer = mainDashboardStore.reducer;
