import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'styled-css-grid';

import { advertiserSelector } from '../../store';
import {
  DescriptionTitleWrapper,
  CellWrapper,
  InfoColumnWrapper,
} from '../styled';
import { IAdvertiser } from '../../types';

import { MarkdownContent } from './MarkdownContent';
import { ImgWrapper } from './styled';

const enum ADVERTISER_FIELDS {
  description = 'description',
  countries = 'countries',
  logo = 'logo',
  name = 'name',
  platforms = 'platforms',
  tradingInstruments = 'tradingInstruments',
  webSite = 'webSite',
}

const FIRST_COLUMN_ADVERTISER_HEADERS = {
  [ADVERTISER_FIELDS.webSite]: 'Web site',
  [ADVERTISER_FIELDS.description]: 'Description',
  [ADVERTISER_FIELDS.countries]: 'Countries',
};
const SECOND_COLUMN_ADVERTISER_HEADERS = {
  [ADVERTISER_FIELDS.platforms]: 'Platform',
  [ADVERTISER_FIELDS.tradingInstruments]: 'Trading Instruments',
};

const mapAdvertiserFields = (
  titles: Record<string, string>,
  values?: IAdvertiser
) =>
  Object.keys(titles).reduce((acc, key) => {
    if (!values?.[key]) return acc;

    return [
      ...acc,
      {
        label: titles[key],
        value: values[key],
      },
    ];
  }, []);

export const AdvertiserContent: React.FC = () => {
  const ad = useSelector(advertiserSelector);
  const { firstColumn, secondColumn } = useMemo(
    () => ({
      firstColumn: mapAdvertiserFields(FIRST_COLUMN_ADVERTISER_HEADERS, ad),
      secondColumn: mapAdvertiserFields(SECOND_COLUMN_ADVERTISER_HEADERS, ad),
    }),
    [ad]
  );

  if (!ad) return <></>;

  return (
    <>
      <ImgWrapper>
        {ad.logo && <img src={ad.logo} alt="" />}
        {ad.name ?? ''}
      </ImgWrapper>
      <Grid columns="1fr 1fr" gap="20px">
        {[firstColumn, secondColumn].map(
          (column) =>
            column.length && (
              <InfoColumnWrapper>
                {column.map((cell) => (
                  <CellWrapper>
                    <DescriptionTitleWrapper>
                      {cell.label}
                    </DescriptionTitleWrapper>
                    <MarkdownContent data={cell.value} />
                  </CellWrapper>
                ))}
              </InfoColumnWrapper>
            )
        )}
      </Grid>
    </>
  );
};
