import React from 'react';

import { Filters, FilterTypes } from '@ams-package/filters';

export const TestFilters: React.FC = (): any => (
  <div style={{ display: 'flex', gap: '20px' }}>
    <Filters
      filters={[
        {
          type: FilterTypes.SingleSelect,
          dictionaryName: 'status',
          name: 'status',
          placeholder: 'Status',
          options: [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
            { label: 'Inactive1', value: 'inactive1' },
            { label: 'Inactive2', value: 'inactive2' },
            { label: 'Inactive3', value: 'inactive3' },
          ],
        },
        {
          type: FilterTypes.MultiSelect,
          dictionaryName: 'country',
          name: 'country',
          placeholder: 'Country',
          options: [
            { label: 'Ukraine', value: 'ukraine' },
            { label: 'USA', value: 'usa' },
            { label: 'Canada', value: 'canada' },
            { label: 'France', value: 'france' },
            { label: 'Germany', value: 'germany' },
          ],
        },
        {
          type: FilterTypes.Date,
          name: 'date',
          placeholder: 'Date',
        },
      ]}
    />
  </div>
);
