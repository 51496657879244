import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { filtersActions } from '@ams-package/filters';
import { Dashboard } from '@ams-package/dashboard';

import { settings } from './columns';
import { apiGetPayoutsHistory } from './api';

export const History = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filtersActions.clearFilters());
  }, []);

  return (
    <Dashboard
      api={{
        apiGetGridData: apiGetPayoutsHistory,
      }}
      withoutUrlChanging
      settings={settings}
    />
  );
};
