import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Add, Minus } from 'iconsax-react';

import { useDictionary } from '@crm-framework/dictionaries';

import { IPayoutPaymentMethodConfigurationModel } from '@ams-package/dictionaries';

import { accountSettingsActions } from '../../store';

import {
  PaymentMethodsInformation,
  PaymentMethodsInformationWrapper,
  PaymentsInfoTitle,
} from './styled';

export const PaymentsInfo: React.FC<{ shouldInit?: boolean }> = ({
  shouldInit = false,
}) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const availablePaymentMethods = useDictionary(
    'payoutPaymentMethods'
  ) as IPayoutPaymentMethodConfigurationModel[];
  const texts = useDictionary('texts') as Record<string, string>;
  const paymentMethodsTextLimits = texts['payouts.paymentMethods.limits.text'];

  useEffect(() => {
    if (shouldInit) dispatch(accountSettingsActions.initPaymentSettings());
  }, []);

  const toggleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const paymentMethodsDescription = useMemo(
    () =>
      availablePaymentMethods?.reduce(
        (result, method) =>
          result
            ?.replace(
              ` {${method.name}_Amount}`,
              `${method.payoutByRequestMinAmount || 0}`
            )
            ?.replace(
              ` {${method.id}_Amount}`,
              `${method.payoutByRequestMinAmount || 0}`
            ),
        paymentMethodsTextLimits
      ),
    [availablePaymentMethods, paymentMethodsTextLimits]
  );

  return (
    <PaymentMethodsInformationWrapper>
      <PaymentsInfoTitle onClick={toggleExpand}>
        Information about payment methods
        {isExpanded ? <Minus /> : <Add />}
      </PaymentsInfoTitle>
      {isExpanded && (
        <PaymentMethodsInformation
          dangerouslySetInnerHTML={{
            __html: paymentMethodsDescription,
          }}
        />
      )}
    </PaymentMethodsInformationWrapper>
  );
};
