import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'login';

export const StepCounterWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'step-counter-wrapper'),
})`
  color: var(--hover-secondary-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const ProgressBar = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'progress-bar'),
})`
  display: flex;
  width: 32px;
  height: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
`;

export const ProgressEmpty = styled(ProgressBar).attrs({
  className: classNameWithPrefix(blockName, 'progress-empty'),
})`
  background: var(--hover-secondary-color);
`;

export const ProgressBarFull = styled(ProgressBar).attrs({
  className: classNameWithPrefix(blockName, 'progress-full'),
})`
  background: var(--primary-color);
`;

export const ProgressBarHalf = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'progress-half'),
})`
  width: 19px;
  height: 6px;
  border-radius: 100px;
  background: var(--primary-color);
`;

export const BottomContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'bottom-content-wrapper'),
})`
  margin-top: auto;
`;

export const DescriptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'description-wrapper'),
})`
  line-height: 22px;
  padding-bottom: 24px;
  color: var(--description-text-color);
`;

export const BottomDescriptionWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'bottom-description-wrapper'),
})`
  line-height: 22px;
  padding-top: 12px;
  text-align: center;
  color: var(--description-text-color);
`;

export const CheckboxWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'checkbox-wrapper'),
})`
  display: flex;
  width: 377px;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--description-text-color);
`;

export const SideModalTitleWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'side-modal-title-wrapper'),
})`
  width: 100%;
  ${generateFontStyle('h1')}
`;
