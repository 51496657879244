import React from 'react';

import { ReduxInject } from '@crm-framework/saga-injector';

import { PaymentRequest } from './PaymentRequest';
import { STORE_NAME, paymentRequestReducer } from './store';
import { watchers } from './sagas';

const PaymentRequestInject = ({ ...props }) => (
  <ReduxInject
    keyName={STORE_NAME}
    saga={watchers}
    reducer={paymentRequestReducer}>
    <PaymentRequest {...props} />
  </ReduxInject>
);

export default PaymentRequestInject;
