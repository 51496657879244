import { takeEvery, takeLatest } from 'redux-saga/effects';

import { paymentEditorActions } from '@ams-package/payment-method-editor';

import { regularPayoutActions } from '../store';

import {
  handleStartInit,
  handleDeleteRegularPaymentMethod,
  handleConfirmDeleteRegularPaymentMethod,
  handleSelectRegularPaymentMethod,
  handleInitPayoutDetails,
  handleSelectRegularPaymentMethodOnCreate,
} from './saga';

export function* watchers() {
  yield takeLatest(regularPayoutActions.initWidget, handleStartInit);
  yield takeLatest(
    regularPayoutActions.select,
    handleSelectRegularPaymentMethod
  );
  yield takeLatest(
    regularPayoutActions.delete,
    handleDeleteRegularPaymentMethod
  );
  yield takeLatest(
    regularPayoutActions.confirmDelete,
    handleConfirmDeleteRegularPaymentMethod
  );
  yield takeEvery(
    paymentEditorActions.onSuccess,
    handleSelectRegularPaymentMethodOnCreate
  );
}

export function* payoutDetailsWatchers() {
  yield takeLatest(
    regularPayoutActions.initPaymentDates,
    handleInitPayoutDetails
  );
}
