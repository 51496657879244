import React from 'react';
import { useSelector } from 'react-redux';

import { signUpInProgress } from '../store';

import {
  StepCounterWrapper,
  ProgressBarFull,
  ProgressEmpty,
  ProgressBarHalf,
  SideModalTitleWrapper,
} from './styled';

export const RegistrationTitle: React.FC = () => {
  const isInProgress = useSelector(signUpInProgress);

  return (
    <>
      <SideModalTitleWrapper>
        {isInProgress ? 'A bit more to go' : "Let's get started"}
      </SideModalTitleWrapper>
      <StepCounterWrapper>
        {isInProgress ? '2/2' : '1/2'}
        {isInProgress ? (
          <ProgressBarFull />
        ) : (
          <ProgressEmpty>
            <ProgressBarHalf />
          </ProgressEmpty>
        )}
      </StepCounterWrapper>
    </>
  );
};
