import React from 'react';

import { TabsWithNavigation } from '@ams-package/tabs';
import { PaymentIcon, HistoryIcon } from '@ams-package/icons';
import { PathNames } from '@ams-package/router';

import { createTabsConfig } from '../../utils';

import { PayoutsRequest } from './PayoutsRequest';
import { History } from './History';

const TAB_PATHS = {
  payments: `${PathNames.payouts}?tabName=payments`,
  history: `${PathNames.payouts}?tabName=history`,
};

export const Payouts: React.FC = () => {
  const tabData = [
    {
      TabIcon: PaymentIcon,
      title: `Payments`,
      Content: PayoutsRequest,
      key: 'payments',
      url: TAB_PATHS.payments,
    },
    {
      TabIcon: HistoryIcon,
      title: `History`,
      Content: History,
      key: 'history',
      url: TAB_PATHS.history,
    },
  ];

  const tabPanels = createTabsConfig(tabData);

  return (
    <>
      <h1>Payouts</h1>
      {tabPanels && <TabsWithNavigation tabPanels={tabPanels} />}
    </>
  );
};
