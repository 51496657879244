import styled, { createGlobalStyle } from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

export const blockName = 'account-settings';

export const AccountSettingsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: column;
`;

export const AccountSettingsCssStyles = createGlobalStyle`
  :root {
    --add-payment-method-button-size: 24px;
    
    @media (max-width: 1200px) {
      --add-payment-method-button-size: 16px;
    }
  }
`;
