import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@ams-package/icons';

import {
  HeaderPayoutDate,
  HeaderPayoutTitle,
  HeaderPayoutWrapper,
} from './styled';
import { regularPayoutActions } from './store';
import { payoutDateSelector } from './selectors';

export const PayoutDate = () => {
  const dispatch = useDispatch();
  const payoutDate = useSelector(payoutDateSelector);

  useEffect(() => {
    dispatch(regularPayoutActions.initPaymentDates());
  }, []);

  return payoutDate ? (
    <HeaderPayoutWrapper>
      <HeaderPayoutTitle>
        <Icon className="wallet-icon" name="wallet" />
        Upcoming payout:
      </HeaderPayoutTitle>
      <HeaderPayoutDate>{payoutDate}</HeaderPayoutDate>
    </HeaderPayoutWrapper>
  ) : null;
};
