import styled from 'styled-components';
import { CloseCircle, TickCircle } from 'iconsax-react';

import { classNameWithPrefix } from '@crm-framework/utils';

import Panel from '@ams-package/panel';
import { generateFontStyle } from '@ams-package/theme';

const blockName = 'regular-payouts';

export const RegularPayoutGrid = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'grid'),
})`
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 12px;
  grid-row-gap: 20px;
`;

export const RegularPayoutContainer = styled(Panel)`
  display: block;
  height: 100%;
`;

export const PaymentRequestTitle = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'title'),
})`
  grid-area: 1 / 1 / 2 / 2;
`;

export const PaymentRequestCheckStatus = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'check-status'),
})`
  grid-area: 1 / 2 / 2 / 2;
  display: flex;
  justify-content: flex-end;
`;

export const PaymentCheckMessage = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'check-message'),
})`
  grid-area: 2 / 1 / 3 / 3;
  color: var(--description-text-color);
  ${generateFontStyle('body-2r')}
`;

export const RegularPayoutField = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'field'),
})`
  grid-area: 4 / 1 / 4 / 3;
`;

export const UpcomingDate = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'upcoming-date'),
})`
  grid-area: 3 / 1 / 3 / 3;
`;

export const UpcomingDateLabel = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'upcoming-date-label'),
})`
  ${generateFontStyle('body-2m')}
`;

export const CloseCircleIcon = styled(CloseCircle).attrs({
  className: classNameWithPrefix(blockName, 'close-circle-icon'),
})`
  line-height: 1.2;
  vertical-align: top;
  margin: 2px;
  color: var(--danger-text-color);
`;

export const TickCircleIcon = styled(TickCircle).attrs({
  className: classNameWithPrefix(blockName, 'tick-circle-icon'),
})`
  line-height: 1.2;
  vertical-align: top;
  margin: 2px;
  color: var(--primary-color);
`;

export const HeaderPayoutDate = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header-payout-date'),
})`
  font-family: Satoshi;
  font-size: var(--header-payout-date-font-size);
  font-weight: 700;
`;

export const HeaderPayoutWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header-payout-wrapper'),
})`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const HeaderPayoutTitle = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header-payout-title'),
})`
  display: flex;
  align-items: center;
  ${generateFontStyle('body-3r')}
  color: var(--primary-color);
  gap: 8px;
`;

export const MinimumAmountWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'minimum-amount-wrapper'),
})`
  ${generateFontStyle('body-3r')}
  color: var(--description-text-color);
  padding-left: 6px;
`;
