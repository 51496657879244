import React from 'react';

import { Select } from '@ams-package/select';
import { SelectPaymentMethod } from '@ams-package/payment-method-editor';
import { Icon } from '@ams-package/icons';

export const TestDropdowns: React.FC = (): any => {
  const [value, setValue] = React.useState(null);
  const [value1, setValue1] = React.useState(null);
  const [value2, setValue2] = React.useState(null);
  const [value3, setValue3] = React.useState(null);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#3A3A3C',
        padding: '10px 100px',
        gap: '20px',
      }}>
      <Select
        value={value}
        label="Dropdown"
        onChange={setValue as any}
        options={[
          {
            label: 'test',
            value: 'test0',
          },
          {
            label: 'test1',
            value: 'test1',
          },
          {
            label: 'test2',
            value: 'test2',
          },
          { label: 'test3', value: 'test3' },
        ]}
      />
      <Select
        disabled
        label="Dropdown"
        options={[
          { label: 'test0', value: 'test0' },
          { label: 'test1', value: 'test1' },
          { label: 'test2', value: 'test2' },
          { label: 'test3', value: 'test3' },
        ]}
      />
      <Select
        isSearchable
        value={value1}
        label="Dropdown"
        onChange={setValue1 as any}
        options={[
          {
            label: 'test0',
            value: 'test0',
            postfix: (
              <div style={{ color: 'red', marginLeft: '10px' }}>postfix</div>
            ),
          },
          { label: 'test1', value: 'test1' },
          { label: 'test2', value: 'test2' },
          { label: 'test3', value: 'test3' },
        ]}
      />
      <SelectPaymentMethod
        isSearchable
        value={value2}
        label="Dropdown"
        onChange={setValue2 as any}
        options={[
          {
            prefix: <Icon name="visa" />,
            label: 'Visa',
            value: '12342134',
            postfix: '4149',
          },
          {
            prefix: <Icon name="webMoney" />,
            label: 'Web Money',
            value: '423142314',
            postfix: '4148',
          },
          {
            prefix: <Icon name="visa" />,
            label: 'Visa',
            value: '321423423',
            postfix: '4147',
          },
          {
            prefix: <Icon name="qiwi" />,
            label: 'Qiwi',
            value: '4234234',
            postfix: '4146',
          },
          {
            prefix: <Icon name="visa" />,
            label: 'Visa',
            value: '12342134',
            postfix: '4149',
          },
          {
            prefix: <Icon name="webMoney" />,
            label: 'Web Money',
            value: '423142314',
            postfix: '4148',
          },
          {
            prefix: <Icon name="visa" />,
            label: 'Visa',
            value: '321423423',
            postfix: '4147',
          },
          {
            prefix: <Icon name="qiwi" />,
            label: 'Qiwi',
            value: '4234234',
            postfix: '4146',
          },
        ]}
      />
      <div style={{ width: 300 }}>
        <SelectPaymentMethod
          isSearchable
          value={value3}
          label="Dropdown"
          onChange={setValue3 as any}
          options={[
            {
              prefix: <Icon name="visa" />,
              label: 'Visa',
              value: '12342134',
              postfix: '4149',
            },
            {
              prefix: <Icon name="webMoney" />,
              label: 'Web Money',
              value: '423142314',
              postfix: '4148',
            },
            {
              prefix: <Icon name="visa" />,
              label: 'Visa',
              value: '321423423',
              postfix: '4147',
            },
            {
              prefix: <Icon name="qiwi" />,
              label: 'Qiwi',
              value: '4234234',
              postfix: '4146',
            },
            {
              prefix: <Icon name="visa" />,
              label: 'Visa',
              value: '12342134',
              postfix: '4149',
            },
            {
              prefix: <Icon name="webMoney" />,
              label: 'Web Money',
              value: '423142314',
              postfix: '4148',
            },
            {
              prefix: <Icon name="visa" />,
              label: 'Visa',
              value: '321423423',
              postfix: '4147',
            },
            {
              prefix: <Icon name="qiwi" />,
              label: 'Qiwi',
              value: '4234234',
              postfix: '4146',
            },
          ]}
        />
      </div>
    </div>
  );
};
