import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { classNameWithPrefix } from '@crm-framework/utils';

import { h3Style } from '@ams-package/theme';

const blockName = 'navigation';

const NavLinkStyles = css`
  display: flex;
  align-items: center;
  gap: 12px;
  ${h3Style}
  color: var(--main-menu-color);
`;

export const StyledNavUl = styled.ul.attrs({
  className: classNameWithPrefix(blockName, 'nav-ul'),
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledNavLink = styled(NavLink).attrs({
  className: classNameWithPrefix(blockName, 'nav-link'),
})`
  ${NavLinkStyles}

  svg {
    width: var(--navigation-icon-size);
    height: var(--navigation-icon-size);
    stroke: var(--main-menu-icon-color);
    fill: var(--main-menu-icon-color);
  }

  &:hover {
    color: var(--main-menu-hover-color);
    svg {
      stroke: var(--main-menu-hover-icon-color);
      fill: var(--main-menu-hover-icon-color);
    }
  }
  &.active {
    color: var(--main-menu-hover-icon-color);
    svg {
      stroke: var(--main-menu-hover-icon-color);
      fill: var(--main-menu-hover-icon-color);
    }
  }
`;

export const DisabledNavLink = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'disabled-nav-link'),
})`
  ${NavLinkStyles}
  pointer-events: none;
  opacity: 0.5;
  svg {
    stroke: var(--main-menu-icon-color);
    fill: var(--main-menu-icon-color);
  }
`;

export const NavIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'nav-icon-wrapper'),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--nav-icon-wrapper-size);
  height: var(--nav-icon-wrapper-size);
  border-radius: 100px;
  padding: var(--nav-icon-wrapper-padding);
  background: var(--modal-background-color);
`;
