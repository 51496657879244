import React from 'react';
import { ArrowDown3, ArrowUp3 } from 'iconsax-react';

import { PercentWrapper } from './styled';

export enum PercentDirections {
  plus = 'plus',
  minus = 'minus',
}

export type PercentType = {
  direction: PercentDirections;
  value: number;
};

export const Percent: React.FC<PercentType> = ({ direction, value }) => {
  if (value === 0) return null;

  return (
    <PercentWrapper isPositive={direction === PercentDirections.plus}>
      {direction === PercentDirections.plus ? <ArrowUp3 /> : <ArrowDown3 />}
      {value === Infinity ? '' : `${value} %`}
    </PercentWrapper>
  );
};
