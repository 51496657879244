import { WretchResponse } from 'wretch';

import { authorizedWretch } from '@ams-package/utils';

import { IPayoutDetails } from '../types';

export const apiSelectRegularPaymentMethod = async (
  paymentMethodId: number
): Promise<WretchResponse> =>
  authorizedWretch(`/api/payment-methods/${paymentMethodId}/regular`)
    .put()
    .res();

export const apiClearRegularPaymentMethod = async (): Promise<WretchResponse> =>
  authorizedWretch(`/api/payment-methods/regular`).delete().res();

export const apiGetPayoutDetails = async (): Promise<IPayoutDetails> =>
  authorizedWretch('/api/summary/payouts').get().json();
