import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { classNameWithPrefix } from '@crm-framework/utils';

import { h4Style } from '@ams-package/theme';
import { affShoreLogo } from '@ams-package/app';

const blockName = 'sidebar';

export const SidebarWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background: var(--sidebar-background-color);
  padding: var(--sidebar-padding);
`;

export const SidebarHeader = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header'),
})`
  display: flex;
  flex-direction: column;
  gap: var(--sidebar-header-gap);
`;

export const SidebarFooter = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'footer'),
})`
  display: flex;
  flex-direction: column;
`;

export const SidebarLogout = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'logout'),
})`
  padding: var(--sidebar-logout-padding);
  border-top: 1px solid var(--border-color);
`;

export const SidebarLogoutLink = styled(NavLink).attrs({
  className: classNameWithPrefix(blockName, 'logout-link'),
})`
  display: flex;
  align-items: center;
  gap: var(--sidebar-logout-link-gap);
  color: var(--logout-text-color);
  ${h4Style}

  svg {
    width: var(--sidebar-logout-link-size);
    height: var(--sidebar-logout-link-size);
    stroke: var(--logout-text-color);
    fill: var(--logout-text-color);
  }

  &:hover {
    color: var(--logout-hover-text-color);
    svg {
      stroke: var(--logout-hover-text-color);
      fill: var(--logout-hover-text-color);
    }
  }
`;

export const HeaderWrapper = styled.img.attrs({
  className: classNameWithPrefix(blockName, 'header-wrapper'),
})`
  content: url(${affShoreLogo});
  width: 150px;
`;
