import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'sidebar-account-info';

export const AccountInfo = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'info'),
})`
  display: flex;
  flex-direction: column;
  gap: var(--sidebar-account-info-gap);
  padding: var(--sidebar-account-info-padding);
`;

export const AccountInfoWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AccountInfoAvatar = styled.img.attrs({
  className: classNameWithPrefix(blockName, 'avatar'),
})`
  width: var(--sidebar-avatar-size);
  height: var(--sidebar-avatar-size);
  border-radius: 100px;
  object-fit: cover;
  background: var(--modal-background-color);
`;

export const AccountInfoDescription = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'description'),
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AccountInfoEmail = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'email'),
})`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 192px;
  ${generateFontStyle('body-1r')}
`;

export const EmailWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'email-wrapper'),
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
