import { AffStatisticsType, IDashboardConfig } from '../types';
import { IOffer } from '../../offers';

export const affStatisticsSelector = (state): AffStatisticsType | null =>
  state.mainDashboard.affStatistics;

export const isDashboardReadySelector = (state): boolean =>
  state.mainDashboard.isReady;

export const topOffersSelector = (state): IOffer[] =>
  state.mainDashboard.topOffers;

export const dashboardConfigSelector = (state): IDashboardConfig | null =>
  state.mainDashboard.config;
