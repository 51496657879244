import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { OffersTabs } from './offers-tabs';
import { offersActions } from './store';
import { OffersWrapper, OfferCssStyles } from './styled';

export const Offers: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(offersActions.initOffers());
  }, []);

  return (
    <OffersWrapper>
      <OfferCssStyles />
      <h1>Offers</h1>
      <OffersTabs />
    </OffersWrapper>
  );
};
