import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'offer';

export const BackButtonWrapper = styled.button.attrs({
  className: classNameWithPrefix(blockName, 'back-button'),
})`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--hover-secondary-color);
  border: var(--hover-secondary-color);
  cursor: pointer;
`;

export const HeaderWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header-wrapper'),
})`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`;

export const BadgeWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'badge-wrapper'),
})`
  font-size: var(--body-1m-font-size);
`;

export const LandingDescription = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'landing-description'),
})`
  ${generateFontStyle('body-3r')}
  color: var(--description-text-color);
`;

export const DescriptionTitleWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'description-title-wrapper'),
})`
  ${generateFontStyle('body-2r')}
  color: var(--description-text-color);
`;

export const ColumnWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'column-wrapper'),
})`
  display: flex;
  flex: 49%;
  flex-direction: column;
`;

export const InfoColumnWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'info-column-wrapper'),
})`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

export const CellWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'cell-wrapper'),
})`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
