import { takeLatest } from 'redux-saga/effects';

import { accountSettingsActions } from '../store';

import { handleChangePassword } from './changePasswordSagas';
import {
  handleChangePersonalInfo,
  handleInitPersonalInfo,
} from './personalInfoSagas';
import { handleInitPaymentSettings } from './paymentSettingsSagas';

export function* watchAccountSettingsSagas() {
  yield takeLatest(accountSettingsActions.changePassword, handleChangePassword);
  yield takeLatest(
    accountSettingsActions.initPersonalInfo,
    handleInitPersonalInfo
  );
  yield takeLatest(
    accountSettingsActions.savePersonalInfo,
    handleChangePersonalInfo
  );
  yield takeLatest(
    accountSettingsActions.initPaymentSettings,
    handleInitPaymentSettings
  );
}
