import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import Panel from '@ams-package/panel';
import { generateFontStyle } from '@ams-package/theme';

const blockName = 'steps';

export const StepsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  gap: 16px;
`;

export const StepCard = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  height: 100%;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--active-color);
  }
`;

export const StepCardInfo = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'card-info'),
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
`;

export const StepHeader = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header'),
})`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Order = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'order'),
})`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: var(--base-black);
  color: var(--active-color);
`;

export const Description = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'description'),
})`
  color: var(--secondary-light-grey);
  ${generateFontStyle('body-2r')}
`;
