import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'dashboard-page';

export const DashboardWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GetStartedTitle = styled.h2.attrs({
  className: classNameWithPrefix(blockName, 'get-started-title'),
})`
  margin-top: 16px;
`;

export const Link = styled(NavLink).attrs({
  className: classNameWithPrefix(blockName, 'link'),
})`
  color: var(--secondary-light-grey);
  text-decoration: underline;
  text-underline-offset: 2px;
  ${generateFontStyle('body-2m')}
`;
