import { createAction } from '@reduxjs/toolkit';

import { STORE_NAME } from '../consts';

export const CHANGE_PASSWORD = `${STORE_NAME}/change-password`;
export const INIT_PERSONAL_INFO = `${STORE_NAME}/init-personal-info`;
export const INIT_PAYMENT_SETTINGS = `${STORE_NAME}/init-payment-settings`;
export const SAVE_PERSONAL_INFO = `${STORE_NAME}/save-personal-info`;

export const accountSettingsActions = {
  changePassword: createAction(CHANGE_PASSWORD),
  initPersonalInfo: createAction(INIT_PERSONAL_INFO),
  savePersonalInfo: createAction(SAVE_PERSONAL_INFO),
  initPaymentSettings: createAction(INIT_PAYMENT_SETTINGS),
};
