import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from '@xcritical/input';

import { CopyIconButton } from '@ams-package/button';
import { InputWrapper } from '@ams-package/input';

import { offersActions, offersLinkQuerySelector } from '../../store';
import { ColumnWrapper } from '../styled';

import { CopyInputIconWrapper, LinksWrapper } from './styled';

export type LinksProps = {
  landingPageId: number;
  campaignId: number;
};

export const Links: React.FC<LinksProps> = ({ landingPageId, campaignId }) => {
  const dispatch = useDispatch();
  const { url, affId, isDisabled, lastCopiedLink } = useSelector(
    offersLinkQuerySelector
  );

  const urlWithQuery = useMemo(
    () =>
      isDisabled || !landingPageId || !campaignId
        ? ''
        : `${url}/?affiliateId=${affId}${
            campaignId ? `&campaignId=${campaignId}` : ''
          }${landingPageId ? `&landingPageId=${landingPageId}` : ''}`,
    [url, landingPageId, affId, campaignId, isDisabled]
  );

  const onCopyClick = useCallback((value: string) => {
    dispatch(offersActions.setLastCopiedLink(value));
  }, []);

  if (!url) return <ColumnWrapper />;

  return (
    <ColumnWrapper>
      <LinksWrapper>
        <div>Link for mixed traffic</div>
        <InputWrapper>
          <Input
            shouldFitContainer
            value={urlWithQuery}
            disabled={isDisabled}
          />
          <CopyInputIconWrapper>
            <CopyIconButton
              onClick={onCopyClick}
              selected={urlWithQuery === lastCopiedLink}
              text={urlWithQuery}
            />
          </CopyInputIconWrapper>
        </InputWrapper>
      </LinksWrapper>
    </ColumnWrapper>
  );
};
