import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Popover } from '@xcritical/popover';

import { paymentEditorActions } from '@ams-package/payment-method-editor';
import { IPaymentMethodsModel } from '@ams-package/dictionaries';
import { defaultImages } from '@ams-package/icons';

import {
  Identification,
  MenuItem,
  MoreIcon,
  PaymentIcon,
  PaymentIconWrapper,
  PaymentMethodWrapper,
  PopoverWrapper,
  Status,
} from './styled';

export type PaymentMethodProps = IPaymentMethodsModel & {
  isConfirmed?: boolean;
};

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  id,
  number,
  code,
  // isConfirmed = false,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const menuItems = useMemo(
    () => [
      {
        label: 'Edit',
        onClick: () => {
          dispatch(
            paymentEditorActions.addOrEdit({
              selectedId: id,
            })
          );
        },
      },
      {
        label: 'Delete',
        onClick: () => {
          dispatch(paymentEditorActions.delete(id));
        },
      },
    ],
    [id]
  );

  const isConfirmed = !!(id % 2);

  return (
    <PaymentMethodWrapper>
      <Status isConfirmed={isConfirmed}>
        {/* TODO add status in future releases */}
      </Status>
      <PopoverWrapper>
        <Popover
          position="bottom right"
          visible={isOpen}
          onOutsideClick={() => setIsOpen(false)}
          appearance="menu"
          withArrow={false}
          trigger="click"
          shouldFitContainer
          content={
            <>
              {menuItems.map(({ label, onClick }) => (
                <MenuItem key={label} onClick={onClick}>
                  {label}
                </MenuItem>
              ))}
            </>
          }>
          <MoreIcon onClick={togglePopover} />
        </Popover>
      </PopoverWrapper>

      <Identification>{number}</Identification>
      <PaymentIconWrapper>
        <PaymentIcon src={defaultImages[code]} />
      </PaymentIconWrapper>
    </PaymentMethodWrapper>
  );
};
