import styled, { css } from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'layout';

const baseLayoutStyles = css`
  display: grid;
  gap: 0px 0px;
  min-height: 100vh;
`;

export const ProtectedLayoutContainer = styled.div`
  ${baseLayoutStyles}
  grid-template-columns: 2fr 9fr;
`;

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const Content = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'content'),
})`
  padding: var(--layout-content-padding);
  margin: var(--layout-content-margin);
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 120px);
`;

export const AuthLayoutContainer = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'auth-layout-container'),
})`
  ${baseLayoutStyles}
  grid-template-columns: 1fr;
`;
