import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'styled-css-grid';

import { Tabs } from '@ams-package/tabs';
import { PathNames } from '@ams-package/router';

import { createTabsConfig } from '../../../../utils';
import { Link } from '../../styled';
import { affStatisticsSelector, mainDashboardActions } from '../../store';

import {
  StatisticsCard,
  TabsHeaderWrapper,
  StatisticTab,
  TabsWrapper,
  HeaderWrapper,
} from './styled';
import { StatisticBlock } from './StatisticBlock';
import { statisticBlocks, statisticPeriods } from './consts';
import { StatisticBlockSkeleton } from './_StatisticBlockSkeleton';

export const Statistics: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedPeriod, setSelectedPeriod] = useState(statisticPeriods.day);
  const statistics = useSelector(affStatisticsSelector);

  const tabData = useMemo(
    () =>
      Object.values(statisticPeriods).map(({ title: tabTitle, name: key }) => ({
        key,
        title: tabTitle,
        HeaderItem: StatisticTab,
        Content: () => (
          <Grid columns="1fr 1fr" rowGap="32px" columnGap="12px">
            {statisticBlocks.map(({ name, title, Icon, render }) => {
              if (!statistics) {
                return (
                  <StatisticBlockSkeleton
                    key={name}
                    title={title}
                    Icon={Icon}
                  />
                );
              }

              const { value, percent } = statistics[name] ?? {};

              return (
                <StatisticBlock
                  key={name}
                  title={title}
                  value={render ? render(value) : value}
                  Icon={Icon}
                  percent={percent}
                />
              );
            })}
          </Grid>
        ),
      })),
    [statistics]
  );

  const onChangeHandler = useCallback((activeTabKey: number | string) => {
    dispatch(mainDashboardActions.getAffStatistics(activeTabKey));
    setSelectedPeriod(statisticPeriods[activeTabKey]);
  }, []);

  const tabPanels = useMemo(() => createTabsConfig(tabData), [tabData]);

  const { start, end } = selectedPeriod;

  return (
    <StatisticsCard>
      <HeaderWrapper>
        <h3>Statistics</h3>
        <Link
          to={`${PathNames.statistics}${
            start && end ? `?date=${start},${end}` : ''
          }`}>
          View all statistics
        </Link>
      </HeaderWrapper>
      {tabPanels?.length && (
        <Tabs
          tabPanels={tabPanels}
          Wrapper={TabsWrapper}
          HeaderWrapper={TabsHeaderWrapper}
          onChange={onChangeHandler}
        />
      )}
    </StatisticsCard>
  );
};
