import React from 'react';
import { Grid } from 'styled-css-grid';

import { Skeleton } from '@ams-package/skeleton';

import { DashboardWrapper } from './styled';

export const DashboardSkeleton = () => (
  <DashboardWrapper>
    <h1>Dashboard</h1>
    <Grid columns="1fr 1fr" gap="16px">
      <Skeleton height={368} />
      <Skeleton height={368} />
    </Grid>
    <Skeleton height="calc(100vh - 600px)" width="100%" />
  </DashboardWrapper>
);
