import React, { useMemo } from 'react';
import { CardEdit, Lock1, User } from 'iconsax-react';

import { TabsWithNavigation } from '@ams-package/tabs';
import { StatusModal } from '@ams-package/modal';
import { PathNames } from '@ams-package/router';

import { createTabsConfig } from '../../utils';

import {
  PersonalInfoTab,
  SecurityTab,
  PaymentSettingsTab,
} from './account-settings-tabs';
import { AccountSettingsCssStyles, AccountSettingsWrapper } from './styled';
import { ACCOUNT_SETTINGS_STATUS_MODAL_NAME } from './consts';

const TAB_PATHS = {
  personalInfo: `${PathNames.accountSettings}?tabName=personalInfo`,
  security: `${PathNames.accountSettings}?tabName=security`,
  paymentSettings: `${PathNames.accountSettings}?tabName=paymentSettings`,
};

export const AccountSettings: React.FC = () => {
  const tabData = useMemo(
    () => [
      {
        TabIcon: () => <User variant="Outline" />,
        title: `Personal Info`,
        Content: PersonalInfoTab,
        key: 'personalInfo',
        url: TAB_PATHS.personalInfo,
      },
      {
        TabIcon: () => <Lock1 variant="Outline" />,
        title: `Security`,
        Content: SecurityTab,
        key: 'security',
        url: TAB_PATHS.security,
      },
      {
        TabIcon: () => <CardEdit variant="Outline" />,
        title: `Payment Settings`,
        Content: PaymentSettingsTab,
        key: 'paymentSettings',
        url: TAB_PATHS.paymentSettings,
      },
    ],
    []
  );

  const tabPanels = useMemo(() => createTabsConfig(tabData), [tabData]);

  return (
    <AccountSettingsWrapper>
      <AccountSettingsCssStyles />
      <h1>Account Settings</h1>
      {tabPanels && <TabsWithNavigation tabPanels={tabPanels} />}
      <StatusModal name={ACCOUNT_SETTINGS_STATUS_MODAL_NAME} />
    </AccountSettingsWrapper>
  );
};
