import { call, select } from 'typed-redux-saga';
import { put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import { xcriticalFormError, xcriticalFormShowErrors } from '@xcritical/forms';

import {
  loginActions,
  resetPasswordFormSelector,
  restorePasswordFormSelector,
} from '../store';
import { RESET_PASSWORD_FORM, RESTORE_PASSWORD_FORM } from '../consts';

import { apiResetPassword, apiRestorePassword } from './api';
import {
  validateResetPasswordModel,
  validateRestorePasswordModel,
} from './validation';

export function* handleResetPassword() {
  try {
    yield put(loginActions.setIsLoading(true));
    const { model } = yield* select(resetPasswordFormSelector);
    const validationErrors = validateResetPasswordModel(model);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(RESET_PASSWORD_FORM, true));
      yield put(xcriticalFormError(RESET_PASSWORD_FORM, validationErrors));

      return;
    }

    yield* call(apiResetPassword, model);

    yield put(loginActions.setIsPasswordReset(true));
  } catch (error) {
    console.error(error);
    const errorMessage = JSON.parse(error.message);
    yield put(
      xcriticalFormError(RESET_PASSWORD_FORM, {
        email: errorMessage.email,
      })
    );
  } finally {
    yield put(loginActions.setIsLoading(false));
  }
}

export function* handleRestorePassword() {
  try {
    yield put(loginActions.setIsLoading(true));
    const { model } = yield* select(restorePasswordFormSelector);
    const validationErrors = validateRestorePasswordModel(model);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(RESTORE_PASSWORD_FORM, true));
      yield put(xcriticalFormError(RESTORE_PASSWORD_FORM, validationErrors));

      return;
    }

    const { token } = queryString.parse(window.location.search);
    yield* call(apiRestorePassword, { code: token as string, ...model });

    yield put(loginActions.setIsPasswordRestored(true));
  } catch (error) {
    console.error(error);
    const errorMessage = JSON.parse(error.message);
    yield put(
      xcriticalFormError(RESTORE_PASSWORD_FORM, {
        confirmPassword: errorMessage.error,
      })
    );
  } finally {
    yield put(loginActions.setIsLoading(false));
  }
}
