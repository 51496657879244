import { call, select } from 'typed-redux-saga';
import { put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import { xcriticalFormError, xcriticalFormShowErrors } from '@xcritical/forms';

import {
  LSHelper,
  LSKeys,
  getErrorTextFromArray,
  getQueryObject,
} from '@ams-package/utils';

import { loginActions, signInFormSelector, signUpFormSelector } from '../store';
import { PERSONAL_DATA_REGISTER_FORM, SIGN_IN_FORM } from '../consts';

import { apiSignIn, apiSignUp, apiValidateRegData } from './api';
import { validatePersonalDataModel, validateSignInModel } from './validation';
import { redirect } from './utils';

export function* handleSignIn() {
  try {
    yield put(loginActions.setIsLoading(true));
    const { model } = yield* select(signInFormSelector);
    const validationErrors = validateSignInModel(model);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(SIGN_IN_FORM, true));
      yield put(xcriticalFormError(SIGN_IN_FORM, validationErrors));

      return;
    }

    const authData = yield* call(apiSignIn, {
      email: model.email,
      password: model.password,
    });
    const { access_token: accessToken, expires_in: expiresIn } = authData;
    yield call(LSHelper.setItem, LSKeys.token, accessToken);
    yield call(LSHelper.setItem, LSKeys.tokenHealth, expiresIn);

    redirect();
  } catch (error) {
    console.error(error);
    const errorMessage = JSON.parse(error.message);
    yield put(
      xcriticalFormError(SIGN_IN_FORM, {
        email: errorMessage.error,
        password: errorMessage.error,
      })
    );
  } finally {
    yield put(loginActions.setIsLoading(false));
  }
}

export function* handleValidate() {
  try {
    yield put(loginActions.setIsLoading(true));
    const { model } = yield* select(signInFormSelector);
    const validationErrors = validateSignInModel(model);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(SIGN_IN_FORM, true));
      yield put(xcriticalFormError(SIGN_IN_FORM, validationErrors));

      return;
    }

    yield* call(apiValidateRegData, {
      email: model.email,
      password: model.password,
    });

    yield put(loginActions.setIsRegistrationInfoValidated());
  } catch (error) {
    console.error(error);
    const errorMessage = JSON.parse(error.message);
    yield put(
      xcriticalFormError(SIGN_IN_FORM, {
        email: errorMessage.email,
        password: errorMessage.password
          ? 'Password is invalid, please read the hint'
          : null,
      })
    );
  } finally {
    yield put(loginActions.setIsLoading(false));
  }
}

export function* handleSignUp() {
  try {
    yield put(loginActions.setIsLoading(true));
    const { model: signInModel } = yield* select(signInFormSelector);
    const { model: personalDataModel } = yield* select(signUpFormSelector);
    const validationErrors = validatePersonalDataModel(personalDataModel);

    if (!isEmpty(validationErrors)) {
      yield put(xcriticalFormShowErrors(PERSONAL_DATA_REGISTER_FORM, true));
      yield put(
        xcriticalFormError(PERSONAL_DATA_REGISTER_FORM, validationErrors)
      );

      return;
    }

    const { refAff, managerId } = getQueryObject();

    yield* call(apiSignUp, {
      ...signInModel,
      ...personalDataModel,
      parentAffiliateId: refAff,
      managerId,
      messengerType: personalDataModel.messengerType?.id,
    });

    yield put(loginActions.signIn());
  } catch (error) {
    console.error(error);
    const errorMessage = JSON.parse(error.message);
    yield put(
      xcriticalFormError(
        PERSONAL_DATA_REGISTER_FORM,
        Object.keys(errorMessage).reduce(
          (errors, key) => ({
            ...errors,
            [key]: getErrorTextFromArray(errorMessage[key]),
          }),
          {}
        )
      )
    );
  } finally {
    yield put(loginActions.setIsLoading(false));
  }
}
