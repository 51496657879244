import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';

const blockName = 'offers-card';

export const CardWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  width: 100%;
  border-radius: 20px;
  background-color: var(--modal-background-color);
  border: 1px solid var(--modal-background-color);
  padding: var(--offers-card-wrapper-padding);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--primary-color);
  }
`;

export const CardHeader = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'header'),
})`
  display: grid;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  padding-bottom: var(--offers-card-header-padding-bottom);
`;

export const CardTitleWrapper = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'title-wrapper'),
})`
  display: flex;
  align-items: center;
  gap: var(--offers-card-title-wrapper-gap);
`;

export const CardTitle = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'title'),
})`
  display: flex;
  align-items: center;
  gap: var(--offers-card-title-gap);
`;

export const CardButtonsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'buttons-wrapper'),
})`
  display: flex;
  justify-content: flex-end;
  gap: var(--offers-card-buttons-wrapper-gap);
`;

export const Link = styled(NavLink).attrs({
  className: classNameWithPrefix(blockName, 'link'),
})`
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-underline-offset: 2px;
  color: var(--link-color);
  ${generateFontStyle('body-2m')};
`;

export const CardBody = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'body'),
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-template-rows: auto;
  gap: 80px;
  padding-top: var(--offers-card-body-padding-top);
  border-top: 1px solid var(--secondary-border-color);
`;

export const CardBodyColumn = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'body-column'),
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;

export const CardColumnTitle = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'column-title'),
})`
  ${generateFontStyle('body-2r')};
  color: var(--description-text-color);
`;

export const CardColumnValue = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'column-value'),
})`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CardStatusColumnValue = styled(CardColumnValue).attrs({
  className: classNameWithPrefix(blockName, 'status-column-value'),
})<{
  isActive: boolean;
}>`
  font-weight: 700;
  color: ${({ isActive }) =>
    isActive ? 'var(--active-color)' : 'var(--danger-text-color)'};
`;
