import { call, put, select } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { xcriticalModalClose, xcriticalModalOpen } from '@xcritical/modal';
import {
  xcriticalFormPropertyChange,
  xcriticalFormReset,
} from '@xcritical/forms';
import { notify } from '@xcritical/notification';

import { handleServerError } from '@ams-package/utils';
import { ButtonsTypes } from '@ams-package/modal';
import { loadAndSaveDictionariesByName } from '@ams-package/dictionaries';
import { OnSuccessActionPayload } from '@ams-package/payment-method-editor';

import { REGULAR_PAYOUT_FORM, regularPayoutActions } from '../store';
import { regularPaymentMethodIdSelector } from '../selectors';
import { REGULAR_PAYOUT_FIELDS } from '../consts';

import {
  apiClearRegularPaymentMethod,
  apiGetPayoutDetails,
  apiSelectRegularPaymentMethod,
} from './api';

export function* handleStartInit() {
  try {
    yield call(loadAndSaveDictionariesByName, [
      'paymentMethods',
      'payoutStatus',
      'payoutPaymentMethods',
    ]);
    yield call(handleSetSelectedPaymentMethod);
    yield put(regularPayoutActions.finishInit());
  } catch (e) {
    console.error(e);
    yield handleServerError({ error: e });
  }
}

export function* handleSelectRegularPaymentMethod(
  { payload }: PayloadAction<number>,
  withoutNotify?: boolean
) {
  try {
    yield call(apiSelectRegularPaymentMethod, payload);

    yield loadAndSaveDictionariesByName(['paymentMethods'], undefined, [
      'paymentMethods',
    ]);

    if (!withoutNotify) {
      notify.success('Your payment method has been successfully saved');
    }
  } catch (e) {
    yield handleServerError({
      error: e,
      statusModalName: 'global',
    });
  }
}

export function* handleDeleteRegularPaymentMethod() {
  yield call(handleSetSelectedPaymentMethod);

  yield put(
    xcriticalModalOpen('confirm', {
      message: {
        title: 'Delete payment method',
        description:
          'Are you sure you want to remove the payment method? If the payment method is not selected, the next regular payout will not be executed.',
      },
      buttons: [
        {
          type: ButtonsTypes.primary,
          title: 'Delete',
          callback: (dispatcher) => {
            dispatcher(regularPayoutActions.confirmDelete());
          },
        },
        {
          type: ButtonsTypes.secondary,
          title: 'Cancel',
          callback: (dispatcher) => {
            dispatcher(xcriticalModalClose('confirm'));
          },
        },
      ],
    })
  );
}

export function* handleConfirmDeleteRegularPaymentMethod() {
  try {
    yield call(apiClearRegularPaymentMethod);
    yield loadAndSaveDictionariesByName(['paymentMethods'], undefined, [
      'paymentMethods',
    ]);
    yield put(xcriticalFormReset(REGULAR_PAYOUT_FORM));
    yield put(xcriticalModalClose('confirm'));
  } catch (e) {
    yield handleServerError({
      error: e,
      statusModalName: 'global',
    });
  }
}

export function* handleInitPayoutDetails() {
  try {
    const payoutDetails = yield* call(apiGetPayoutDetails);
    yield put(regularPayoutActions.setPayoutDetails(payoutDetails));
  } catch (e) {
    console.error(e);
    yield handleServerError({ error: e });
  }
}

export function* handleSetSelectedPaymentMethod() {
  const methodId = yield* select(regularPaymentMethodIdSelector);

  yield put(
    xcriticalFormPropertyChange(
      REGULAR_PAYOUT_FORM,
      REGULAR_PAYOUT_FIELDS.methodId,
      methodId
    )
  );
}

export function* handleSelectRegularPaymentMethodOnCreate({
  payload,
}: PayloadAction<OnSuccessActionPayload>) {
  if (payload.formName === REGULAR_PAYOUT_FORM && payload.id) {
    yield call(handleSelectRegularPaymentMethod, { payload: payload.id }, true);
    yield call(handleSetSelectedPaymentMethod);
  }
}
