import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { SideModal } from '@ams-package/side-modal';
import { Button } from '@ams-package/button';
import { StatusMessage, StatusModalTypes } from '@ams-package/status-message';
import { useKeyPress } from '@ams-package/utils';

import {
  DescriptionWrapper,
  BottomContentWrapper,
  RestorePasswordForm,
  SideModalTitleWrapper,
} from './components';
import { getIsPasswordRestored, loginActions } from './store';
import { LoginPageWrapper, LoginHeader, StatusMessageWrapper } from './styled';
import { HEADER } from './consts';

export const RestorePassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRestored = useSelector(getIsPasswordRestored);

  const handlerRestorePassword = () => {
    dispatch(loginActions.restorePassword());
  };

  const handlerGoToLogin = () => {
    dispatch(loginActions.setIsPasswordRestored(false));
    navigate('/login');
  };
  // TODO handle case when we have few forms on the page
  useKeyPress('Enter', isRestored ? handlerGoToLogin : handlerRestorePassword);

  return (
    <>
      <LoginHeader>{HEADER}</LoginHeader>
      <LoginPageWrapper>
        <SideModal
          isOpen
          title={
            isRestored ? (
              ''
            ) : (
              <SideModalTitleWrapper>
                Change current password
              </SideModalTitleWrapper>
            )
          }
          appearance="login"
          withCloseButton={false}>
          {isRestored ? (
            <>
              <StatusMessageWrapper>
                <StatusMessage
                  type={StatusModalTypes.Success}
                  title="New password saved successfully"
                />
              </StatusMessageWrapper>
              <BottomContentWrapper>
                <Button shouldFitContent onClick={handlerGoToLogin}>
                  Back to login
                </Button>
              </BottomContentWrapper>
            </>
          ) : (
            <>
              <DescriptionWrapper>Create a new password</DescriptionWrapper>
              <RestorePasswordForm />
              <BottomContentWrapper>
                <Button shouldFitContent onClick={handlerRestorePassword}>
                  Submit
                </Button>
              </BottomContentWrapper>
            </>
          )}
        </SideModal>
      </LoginPageWrapper>
    </>
  );
};
