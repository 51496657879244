import { formSelector } from '@xcritical/forms';

import {
  SIGN_IN_FORM,
  STORE_NAME,
  PERSONAL_DATA_REGISTER_FORM,
  RESET_PASSWORD_FORM,
  RESTORE_PASSWORD_FORM,
} from '../consts';
import {
  ISignInFormState,
  IPersonalDataFormState,
  IResetPasswordFormState,
  IRestorePasswordFormState,
} from '../types';

export const signInFormSelector = (state): ISignInFormState =>
  formSelector(state, SIGN_IN_FORM);

export const signUpFormSelector = (state): IPersonalDataFormState =>
  formSelector(state, PERSONAL_DATA_REGISTER_FORM);

export const resetPasswordFormSelector = (state): IResetPasswordFormState =>
  formSelector(state, RESET_PASSWORD_FORM);

export const restorePasswordFormSelector = (state): IRestorePasswordFormState =>
  formSelector(state, RESTORE_PASSWORD_FORM);

export const messengerTypeSelector = (state): string | undefined =>
  signUpFormSelector(state).model.messengerType?.id;

export const signUpInProgress = (state): boolean =>
  state[STORE_NAME]?.isRegistrationInfoValidated ?? false;

export const getIsLoading = (state): boolean => state[STORE_NAME].isLoading;
export const getIsPasswordReset = (state): boolean =>
  state[STORE_NAME].isPasswordReset;
export const getIsPasswordRestored = (state): boolean =>
  state[STORE_NAME].isPasswordRestored;
