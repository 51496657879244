import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SideModal } from '@ams-package/side-modal';
import { Button } from '@ams-package/button';
import { useKeyPress } from '@ams-package/utils';

import { RegistrationTitle, SideModalContent } from './components';
import {
  loginActions,
  signUpInProgress,
  signInFormSelector,
  getIsLoading,
} from './store';
import { LoginPageWrapper, LoginHeader } from './styled';
import { HEADER } from './consts';

export const Registration: React.FC = () => {
  const dispatch = useDispatch();
  const isInProgress = useSelector(signUpInProgress);
  const { model } = useSelector(signInFormSelector);
  const isLoading = useSelector(getIsLoading);

  const handlerValidateRegistrationData = () => {
    dispatch(loginActions.validateRegisterForm());
  };

  const handlerSignUp = () => {
    dispatch(loginActions.signUp());
  };
  // TODO handle case when we have few forms on the page
  useKeyPress(
    'Enter',
    isInProgress ? handlerSignUp : handlerValidateRegistrationData
  );

  return (
    <>
      <LoginHeader>{HEADER}</LoginHeader>
      <LoginPageWrapper>
        <SideModal
          isOpen
          appearance="login"
          title={<RegistrationTitle />}
          withCloseButton={false}>
          <SideModalContent
            pageButton={
              isInProgress ? (
                <Button
                  shouldFitContent
                  onClick={handlerSignUp}
                  disabled={isLoading}>
                  Get Started
                </Button>
              ) : (
                <Button
                  shouldFitContent
                  onClick={handlerValidateRegistrationData}
                  disabled={!model.isTermsAccepted || isLoading}>
                  Continue
                </Button>
              )
            }
          />
        </SideModal>
      </LoginPageWrapper>
    </>
  );
};
