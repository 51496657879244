import styled from 'styled-components';
import { CloseCircle, TickCircle } from 'iconsax-react';

import Button from '@xcritical/button';

import { classNameWithPrefix } from '@crm-framework/utils';

import Panel from '@ams-package/panel';
import { generateFontStyle } from '@ams-package/theme';

const blockName = 'payment-request';

export const PaymentRequestContainer = styled(Panel)`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: repeat(5, auto);
  grid-column-gap: 12px;
  grid-row-gap: 20px;
  min-width: 350px;
`;

export const PaymentRequestTitle = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'title'),
})`
  grid-area: 1 / 1 / 2 / 2;
`;

export const PaymentRequestCheckStatus = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'check-status'),
})`
  grid-area: 1 / 2 / 2 / 2;
  display: flex;
  justify-content: flex-end;
`;

export const PaymentCheckMessage = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'check-message'),
})`
  grid-area: 2 / 1 / 3 / 3;
  color: var(--description-text-color);
  ${generateFontStyle('body-2r')}
`;

export const PaymentAvailableBalance = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'available-balance'),
})`
  grid-area: 3 / 1 / 4 / 3;
`;

export const PaymentAmount = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'amount'),
})`
  grid-area: 4 / 1 / 5 / 2;
`;

export const PaymentMethod = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'method'),
})`
  grid-area: 4 / 2 / 5 / 3;
`;

export const PaymentButton = styled(Button).attrs({
  className: classNameWithPrefix(blockName, 'button'),
})`
  grid-area: 5 / 1 / 6 / 3;
  margin-top: 10px;
`;

export const CloseCircleIcon = styled(CloseCircle).attrs({
  className: classNameWithPrefix(blockName, 'close-circle-icon'),
})`
  line-height: 1.2;
  vertical-align: top;
  margin: 2px;
  color: var(--danger-text-color);
`;

export const TickCircleIcon = styled(TickCircle).attrs({
  className: classNameWithPrefix(blockName, 'tick-circle-icon'),
})`
  line-height: 1.2;
  vertical-align: top;
  margin: 2px;
  color: var(--primary-color);
`;

export const MinimumAmountWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'minimum-amount-wrapper'),
})`
  ${generateFontStyle('body-3r')}
  color: var(--description-text-color);
  padding-left: 6px;
`;
