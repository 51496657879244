export interface IStatistic {
  hits: number;
  uniques: number;
  regs: number;
  ftd: number;
  deposits: number;
  profit: number;
  date: string;
  group: string;
}

export enum StatusTypes {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Approved = 'Approved',
  Confirmed = 'Confirmed',
  Rejected = 'Rejected',
  WaitingForPayment = 'WaitingForPayment',
  Paid = 'Paid',
}
