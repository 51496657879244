import styled, { createGlobalStyle } from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

const blockName = 'offers';

export const OfferCssStyles = createGlobalStyle`
  :root {
    // size:
    --offers-card-icon-size: 40px;
    --offers-card-button-max-width: 160px;

    // gap:
    --offers-card-title-wrapper-gap: 24px;
    --offers-card-title-gap: 16px;
    --offers-card-buttons-wrapper-gap: 32px;

    // padding:
    --offers-card-wrapper-padding: 24px;
    --offers-card-header-padding-bottom: 16px;
    --offers-card-body-padding-top: 16px;

    @media (min-width: 1700px) {
      //size:
      --offers-card-icon-size: 48px;
      --offers-card-button-max-width: 300px;

      // gap:
      --offers-card-title-wrapper-gap: 32px;
      --offers-card-title-gap: 20px;
      --offers-card-buttons-wrapper-gap: 40px;

      // padding:
      --offers-card-wrapper-padding: 32px;
      --offers-card-header-padding-bottom: 20px;
      --offers-card-body-padding-top: 20px;
    }

    @media (max-width: 1200px) {
      //size:
      --offers-card-icon-size: 32px;

      // gap:
      --offers-card-title-wrapper-gap: 20px;
      --offers-card-buttons-wrapper-gap: 24px;

      // padding:
      --offers-card-wrapper-padding: 20px;
      --offers-card-header-padding-bottom: 12px;
      --offers-card-body-padding-top: 12px;
    }
  }
`;

export const OffersWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper'),
})`
  display: flex;
  flex-direction: column;
`;
