import { authorizedWretch } from '@ams-package/utils';

import { IChangePasswordModel, IPersonalInfoRequestModel } from '../types';

export const changePassword = async (
  payload: IChangePasswordModel
): Promise<Response> => authorizedWretch(`/api/password`).put(payload).res();

export const savePersonalInfo = async (
  payload: IPersonalInfoRequestModel
): Promise<Response> =>
  authorizedWretch(`/api/me/personal-info`).put(payload).res();
