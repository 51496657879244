export const STORE_NAME = '@account-settings';

export const CHANGE_PASSWORD_FORM = 'changePasswordForm';

export const CHANGE_PASSWORD_FORM_FIELDS = {
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  confirmPassword: 'confirmPassword',
};

export const PERSONAL_INFO_FORM = 'personalInfoForm';
export const ACCOUNT_SETTINGS_STATUS_MODAL_NAME = 'accountSettingsStatusModal';

export const CHANGE_PASSWORD_IS_LOADING = 'changePasswordIsLoading';
export const PERSONAL_INFO_IS_READY = 'personalInfoIsReady';
export const PERSONAL_INFO_IS_LOADING = 'personalInfoIsLoading';
export const PAYMENT_SETTINGS_IS_LOADING = 'paymentSettingsIsLoading';

export const PERSONAL_INFO_FORM_FIELDS = {
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  phone: 'phone',
  birthday: 'birthday',
  country: 'country',
  whatsApp: 'whatsApp',
  viber: 'viber',
  telegram: 'telegram',
  skype: 'skype',
} as const;

export const PERSONAL_INFO_REQUIRED_FIELDS: Record<
  keyof typeof PERSONAL_INFO_FORM_FIELDS,
  boolean
> = {
  [PERSONAL_INFO_FORM_FIELDS.email]: true,
  [PERSONAL_INFO_FORM_FIELDS.firstname]: true,
  [PERSONAL_INFO_FORM_FIELDS.lastname]: true,
  [PERSONAL_INFO_FORM_FIELDS.phone]: true,
  [PERSONAL_INFO_FORM_FIELDS.birthday]: true,
  [PERSONAL_INFO_FORM_FIELDS.country]: true,
  [PERSONAL_INFO_FORM_FIELDS.whatsApp]: false,
  [PERSONAL_INFO_FORM_FIELDS.telegram]: false,
  [PERSONAL_INFO_FORM_FIELDS.viber]: false,
  [PERSONAL_INFO_FORM_FIELDS.skype]: false,
};

export const PERSONAL_INFO_GRID_SETTINGS = {
  columns: '342px 342px',
  rowGap: '20px',
  columnGap: '78px',
};
