import { takeLatest } from 'redux-saga/effects';

import { offersActions } from '../store';

import { handleOffersInit } from './offersSagas';
import { handleOfferInit, handleGetLandingPages } from './offerSagas';

export * from './api';

export function* watchOffersSagas() {
  yield takeLatest(offersActions.initOffers, handleOffersInit);
  yield takeLatest(offersActions.initOffer, handleOfferInit);
  yield takeLatest(offersActions.getLandingPages, handleGetLandingPages);
}
