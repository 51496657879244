import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useNavigate } from 'react-router';

import Badge from '@xcritical/badge';
import { notify } from '@xcritical/notification';

import { PathNames } from '@ams-package/router';
import { Button } from '@ams-package/button';
import { CopySuccessContent } from '@ams-package/utils';

import {
  CardHeader,
  CardTitleWrapper,
  CardTitle,
  // CardIcon,
  CardButtonsWrapper,
  Link,
} from './styled';

export const OfferCardHeader: React.FC<{
  id: number;
  name: string;
  isTopOffer: boolean;
  isActive: boolean;
  hasOneLanding: boolean;
  link: string | null;
  handlerOpenCard: () => void;
}> = ({
  id,
  name,
  isTopOffer,
  isActive,
  hasOneLanding,
  link,
  handlerOpenCard,
}) => {
  const navigate = useNavigate();
  const [copiedText, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (copiedText.value) {
      notify.success(<CopySuccessContent />);
    }
  }, [copiedText]);

  const copyButton = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      if (link && hasOneLanding) {
        copyToClipboard(link);
      } else {
        navigate(PathNames.offer.replace(':id', String(id)));
      }
    },
    [id, link, hasOneLanding]
  );

  return (
    <CardHeader>
      <CardTitleWrapper>
        <CardTitle>
          {/* TODO: add logo after it will be decided where it should be came from */}
          {/* <CardIcon>
            <Icon name="iqOption" />
          </CardIcon> */}
          {name}
        </CardTitle>
        {isTopOffer && (
          <Badge baseAppearance="default" appearance="info">
            TOP OFFER
          </Badge>
        )}
      </CardTitleWrapper>
      <CardButtonsWrapper>
        {isActive ? (
          <>
            <Link
              to={`${PathNames.statistics}?campaign=${name}`}
              onClick={(e) => e.stopPropagation()}>
              View statistics
            </Link>
            <Button
              baseAppearance="default"
              appearance="offerCardButton"
              onClick={copyButton}>
              Copy link
            </Button>
          </>
        ) : (
          <Button
            baseAppearance="info"
            appearance="offerCardButton"
            onClick={handlerOpenCard}>
            Activate
          </Button>
        )}
      </CardButtonsWrapper>
    </CardHeader>
  );
};
