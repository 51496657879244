import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPayoutDetails, IRegularPayoutState } from './types';

const initialState: IRegularPayoutState = {
  isReady: false,
  payoutDetails: null,
};

export const REGULAR_PAYOUT_FORM = 'REGULAR_PAYOUT_FORM';
export const STORE_NAME = '@regular-payouts';

const regularPayoutStore = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    finishInit: (state) => {
      state.isReady = true;
    },
    setPayoutDetails: (state, action: PayloadAction<IPayoutDetails>) => {
      state.payoutDetails = action.payload;
    },
  },
});

const INIT_WIDGET = `${STORE_NAME}/init-widget` as const;
const INIT_PAYMENT_DATES = `${STORE_NAME}/init-payment-dates` as const;
const SELECT = `${STORE_NAME}/select` as const;
const DELETE = `${STORE_NAME}/delete` as const;
const CONFIRM_DELETE = `${STORE_NAME}/confirm-delete` as const;

export const regularPayoutActions = {
  ...regularPayoutStore.actions,
  initWidget: createAction(INIT_WIDGET),
  select: createAction<number>(SELECT),
  delete: createAction(DELETE),
  confirmDelete: createAction(CONFIRM_DELETE),
  initPaymentDates: createAction(INIT_PAYMENT_DATES),
};

export const regularPayoutReducer = regularPayoutStore.reducer;
