import React from 'react';
import { useDispatch } from 'react-redux';

import { xcriticalModalClose, xcriticalModalOpen } from '@xcritical/modal';

import { StatusModalTypes } from '@ams-package/status-message';
import {
  Modal,
  StatusModal,
  WithoutHeaderModal,
  ButtonsTypes,
} from '@ams-package/modal';
import { SideModal } from '@ams-package/side-modal';

export const TestModals: React.FC = (): any => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = (name) => {
    dispatch(xcriticalModalOpen(name));
  };
  const showSuccessModal = (name) => {
    dispatch(
      xcriticalModalOpen(name, {
        message: {
          type: StatusModalTypes.Success,
          title: 'Request successfully accepted',
          description:
            'We have accepted the withdrawal request, when we approve it, the funds will be credited to your account. Keep in touch with your manager',
        },
      })
    );
  };
  const showFailureModal = (name) => {
    dispatch(
      xcriticalModalOpen(name, {
        message: {
          type: StatusModalTypes.Failure,
          title: 'Connection error',
          description:
            'No internet connection. Check your connection and try again',
        },
        buttons: {
          type: ButtonsTypes.primary,
          title: 'Try again',
          callback: (dispatcher) => {
            dispatcher(xcriticalModalClose(name));
          },
        },
      })
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <button onClick={() => setIsOpen(true)}>show side modal</button>
      <SideModal
        isOpen={isOpen}
        title="Edit payment method"
        onClose={() => {
          setIsOpen(false);
        }}>
        hello
      </SideModal>
      <button onClick={() => showSuccessModal('status')}>
        show success modal
      </button>
      <button onClick={() => showFailureModal('status')}>
        show failure modal
      </button>
      <StatusModal name="status" />

      <button onClick={() => showModal('default')}>show Default modal</button>
      <Modal name="default" title="Default">
        Default
      </Modal>

      <button onClick={() => showModal('withoutHeader')}>
        show WithoutHeaderModal modal
      </button>
      <WithoutHeaderModal name="withoutHeader">
        WithoutHeaderModal
      </WithoutHeaderModal>
    </div>
  );
};
