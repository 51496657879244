import styled from 'styled-components';
import { Add, More } from 'iconsax-react';

import { classNameWithPrefix } from '@crm-framework/utils';

import { generateFontStyle } from '@ams-package/theme';
import Panel from '@ams-package/panel';

import { blockName } from '../../styled';

export const Wrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payment-settings-wrapper'),
})`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WrapperWithBorder = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'wrapper-with-border'),
})`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--secondary-border-color);
  padding-bottom: 24px;
  gap: 16px;
  max-width: 740px;
`;

export const EmptyMessage = styled.h3.attrs({
  className: classNameWithPrefix(blockName, 'empty-message'),
})`
  color: var(--hover-secondary-color);
  padding: 12px 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlusIcon = styled(Add).attrs({
  className: classNameWithPrefix(blockName, 'plus-icon'),
})`
  width: var(--add-payment-method-button-size);
  height: var(--add-payment-method-button-size);
`;

export const PaymentMethodWrapper = styled(Panel)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  padding: 12px;
`;

export const PopoverWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'popover-wrapper'),
})`
  margin-left: auto;
`;

export const MenuItem = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'menu-item'),
})`
  ${generateFontStyle('body-2r')}
  margin: 0 16px;
  padding: 12px 0;
  color: var(--base-white);

  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-light-grey);
  }
`;

export const Status = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'status'),
})<{ isConfirmed: boolean }>`
  white-space: pre;
  ${generateFontStyle('body-2r')}
  color: ${({ isConfirmed }) =>
    isConfirmed ? 'var(--primary-color)' : 'var(--danger-text-color)'};
`;

export const MoreIcon = styled(More).attrs({
  className: classNameWithPrefix(blockName, 'more-icon'),
})`
  cursor: pointer;
  fill: var(--hover-light-color);
  stroke: var(--hover-light-color);
`;

export const Identification = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'identification'),
})`
  ${generateFontStyle('body-2r')}
  color: var(--secondary-light-grey);
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

export const PaymentIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payment-icon-wrapper'),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  background: var(--hover-light-color);
  max-width: 80px;
  max-height: 30px;
  min-height: 30px;
  border-radius: 52px;
`;

export const PaymentIcon = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payment-icon'),
})<{ src: string }>`
  width: 60px;
  height: 20px;
  background: url(${({ src }) => src}) no-repeat center;
  background-size: contain;
`;

export const SupportedPaymentsWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'supported-payments-wrapper'),
})`
  display: flex;
  width: fit-content;
  gap: 8px;
  border-radius: 12px;
  padding: 16px;
  background: var(--dark-grey);
`;

export const SupportedPaymentIconWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'supported-payment-icon-wrapper'),
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  background: var(--hover-light-color);
  max-width: 60px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 8px;
`;

export const PaymentMethodsInformationWrapper = styled.div.attrs({
  className: classNameWithPrefix(
    blockName,
    'payment-methods-information-wrapper'
  ),
})`
  display: flex;
  flex-direction: column;
`;

export const PaymentsInfoTitle = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payments-info-title'),
})`
  ${generateFontStyle('body-1r')}
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const PaymentMethodsInformation = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'payment-methods-information'),
})`
  margin-top: 10px;
  ${generateFontStyle('body-2r')}
`;
