import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@ams-package/button';
import { getUser } from '@ams-package/app';
import { toCurrency } from '@ams-package/utils';

import { PayoutDateInject } from '../regular-payouts';

import {
  HeaderSection,
  HeaderWrapper,
  HeaderInfoWrapper,
  HeaderButtonContent,
} from './styles';

export const Header: React.FC = () => {
  const user = useSelector(getUser);

  return (
    <HeaderSection>
      <HeaderWrapper>
        <h3>{`${user?.firstname} ${user?.lastname}`}</h3>
        <HeaderInfoWrapper>
          <PayoutDateInject />
          <Button baseAppearance="default" appearance="headerBalanceButton">
            <HeaderButtonContent>
              Balance
              <h2>$ {toCurrency(user?.balance)}</h2>
            </HeaderButtonContent>
          </Button>
        </HeaderInfoWrapper>
      </HeaderWrapper>
    </HeaderSection>
  );
};
