import React from 'react';

import Badge from '@xcritical/badge';

import { svgComponents } from '@ams-package/icons';

import { routerConfig } from '../../config';

import {
  StyledNavUl,
  StyledNavLink,
  DisabledNavLink,
  NavIconWrapper,
} from './styles';

export const Navigation = (): any => (
  <nav>
    <StyledNavUl>
      {routerConfig.map((menuItem) => {
        const { name, path, disabled, icon, isMenu } = menuItem;
        const CustomIcon = icon ? svgComponents[icon] : null;

        if (!isMenu) {
          return null;
        }

        return (
          <li key={name}>
            {disabled ? (
              <DisabledNavLink>
                <NavIconWrapper>{icon && <CustomIcon />}</NavIconWrapper>
                {name}
                <Badge baseAppearance="default" appearance="info">
                  SOON
                </Badge>
              </DisabledNavLink>
            ) : (
              <StyledNavLink to={path}>
                <NavIconWrapper>{icon && <CustomIcon />}</NavIconWrapper>
                {name}
              </StyledNavLink>
            )}
          </li>
        );
      })}
    </StyledNavUl>
  </nav>
);
