import { all } from 'redux-saga/effects';

import { watchApp } from '@ams-package/app';

import { watchLoginSagas } from '../pages/login/sagas';
import { watchOffersSagas } from '../pages/offers/sagas';
import { watchAccountSettingsSagas } from '../pages/account-settings/sagas';
import { watchMainDashboardSagas } from '../pages/dashboard';

const appSagas: any = [
  watchLoginSagas,
  watchApp,
  watchOffersSagas,
  watchAccountSettingsSagas,
  watchMainDashboardSagas,
];

export function* watchAppSaga() {
  yield all(appSagas.map((saga) => saga()));
}
