import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import background from './login.png';

const blockName = 'login';

export const ContentWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'content-wrapper'),
})`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  border-radius: var(--default-border-radius);
  align-content: space-between;
`;

export const LoginHeader = styled.h1.attrs({
  className: classNameWithPrefix(blockName, 'header'),
})`
  position: fixed;
  left: 100px;
  bottom: 100px;
  font-family: Montserrat;
  font-size: 128px;
  font-weight: 700;
  line-height: 120%;
  width: 590px;
  overflow-wrap: break-word;
  color: var(--hover-light-color);
  z-index: 11;
`;

export const LabelWithLinkWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'label-with-link-wrapper'),
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ForgotPasswordLinkWrapper = styled.a.attrs({
  className: classNameWithPrefix(blockName, 'forgot-password-link-wrapper'),
})`
  color: var(--description-text-color) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 8px;
`;

export const TermsAndConditionsWrapper = styled(
  ForgotPasswordLinkWrapper
).attrs({
  className: classNameWithPrefix(blockName, 'terms-and-conditions-wrapper'),
})`
  text-decoration: underline;
  margin-bottom: 0px;
  line-height: 100%;
`;

export const BackButtonWrapper = styled.button.attrs({
  className: classNameWithPrefix(blockName, 'back-button-wrapper'),
})`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--hover-secondary-color);
  border: var(--hover-secondary-color);
  margin-right: 16px;
  cursor: pointer;
`;

export const LoginPageWrapper = styled.h1.attrs({
  className: classNameWithPrefix(blockName, 'page-wrapper'),
})`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  &:before {
    position: absolute;
    content: '';
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    z-index: -1;
    background-color: transparent;
    background-repeat: round;
    background-image: url(${background});
    transform: scale(1, -1);
  }
`;

export const StatusMessageWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'status-message-wrapper'),
})`
  position: absolute;
  top: 35%;
  text-align: center;
  width: 90%;
`;
