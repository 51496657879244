import { WretchResponse } from 'wretch/types';

import { authorizedWretch } from '@ams-package/utils';

import { IPayoutCreateModel } from '../types';

export const apiCreatePayout = async (
  model: IPayoutCreateModel
): Promise<WretchResponse> =>
  authorizedWretch('/api/payouts/manual')
    .post({
      paymentMethodId: model.methodId,
      amount: model.amount,
    })
    .res();
