import { addDays } from 'date-fns';

import { authorizedWretch, formatDateForServer } from '@ams-package/utils';
import {
  FilterRequestModelType,
  FilterValuesType,
  parseUniversalFilters,
} from '@ams-package/filters';
import {
  APIGetItemsCallbackResponseType,
  IDashboardGridControllers,
  IDashboardRequestModel,
} from '@ams-package/dashboard';

export const apiGetPayoutsHistory = async (
  filters: FilterValuesType,
  { sorting, pageSize, page }: IDashboardGridControllers
): Promise<APIGetItemsCallbackResponseType> => {
  const $filters = Object.entries(filters || {}).reduce(
    (acc, [name, value]) => {
      if (name === 'date' && value) {
        acc[name] = [
          formatDateForServer(value[0]),
          formatDateForServer(addDays(value[1], 1)),
        ];
      } else {
        acc[name] = value;
      }

      return acc;
    },
    {}
  );
  const filtersRequestModel: FilterRequestModelType[] = parseUniversalFilters(
    ['date', 'type', 'status'],
    $filters,
    { date: 'Between' }
  );
  const requestModel: IDashboardRequestModel = {
    filters: filtersRequestModel,
    sorting: sorting ? [sorting] : undefined,
    limit: pageSize,
    offset: (page - 1) * pageSize,
  };

  return authorizedWretch('/api/payouts-history').post(requestModel).json();
};
