import React from 'react';

import { Link } from '../../styled';

import {
  Description,
  Order,
  StepCard,
  StepHeader,
  StepCardInfo,
} from './styled';

export type StepType = {
  id: number;
  title: string;
  description: string;
  linkText?: string;
  linkUrl?: string;
};

export const Step: React.FC<StepType> = ({
  title,
  id,
  description,
  linkUrl,
  linkText,
}) => (
  <StepCard>
    <StepCardInfo>
      <StepHeader>
        <Order>{id}</Order>
        <h3>{title}</h3>
      </StepHeader>
      <Description>{description}</Description>
    </StepCardInfo>
    {linkText && linkUrl && <Link to={linkUrl}>{linkText}</Link>}
  </StepCard>
);
