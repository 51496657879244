import styled from 'styled-components';

import { classNameWithPrefix } from '@crm-framework/utils';

import { blockName } from '../styled';

export const SecurityTabWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'security-tab-wrapper'),
})`
  display: flex;
  flex-direction: column;
  max-width: 342px;
  gap: 20px;
`;

export const SubmitButtonWrapper = styled.div.attrs({
  className: classNameWithPrefix(blockName, 'submit-button-wrapper'),
})`
  margin-top: 12px;
`;
