import { createSelector } from '@reduxjs/toolkit';

import { IFormState, formSelector } from '@xcritical/forms';

import { getDictionarySelect } from '@ams-package/dictionaries';
import { getLimits, getUser } from '@ams-package/app';

import { PAYMENT_REQUEST_FORM, STORE_NAME } from './store';
import {
  IPaymentRequestState,
  ISubmitPaymentModel,
  IUserPaymentMethodModel,
} from './types';

export const requestPaymentFormSelector = (
  state
): IFormState<{ amount: number; methodId?: number }> =>
  formSelector(state, PAYMENT_REQUEST_FORM);

export const requestPaymentSelector = (state): IPaymentRequestState =>
  state[STORE_NAME];

export const availablePaymentsSelector = createSelector(
  getDictionarySelect('paymentMethods'),
  getDictionarySelect('payoutPaymentMethods'),
  (paymentMethods, payoutPaymentMethods) =>
    paymentMethods.map((method) => ({
      ...method,
      id: method.id as number,
      postfix: method.number.toString(),
      payoutByRequestMinAmount: payoutPaymentMethods.find(
        (payoutMethod) => payoutMethod.id === method.code
      )!.payoutByRequestMinAmount,
    })) as IUserPaymentMethodModel[]
);

export const amountSelector = (state) => requestPaymentSelector(state).amount;

export const manualMinBalanceLimitSelector = (state) =>
  getLimits(state)?.manualPayoutRequestMinBalanceLimit ?? 0;

export const isReadySelector = (state) => requestPaymentSelector(state).isReady;

export const selectedPaymentSelector = createSelector(
  availablePaymentsSelector,
  requestPaymentFormSelector,
  (payments, { model }) =>
    payments.find((payment) => payment.id === model.methodId)
);

export const submitSelector = createSelector(
  selectedPaymentSelector,
  amountSelector,
  requestPaymentFormSelector,
  (currentPayment, amount, { model }) =>
    ({
      currentPayment,
      balance: amount,
      methodId: model.methodId,
      amount: model.amount,
    } as ISubmitPaymentModel)
);

export const viewSelector = createSelector(
  amountSelector,
  manualMinBalanceLimitSelector,
  getUser,
  selectedPaymentSelector,
  isReadySelector,
  (amount, minBalance, user, selectedPayment, isReady) => ({
    balance: amount,
    availableWithdrawal:
      amount && amount >= minBalance && user?.status === 'Active' && isReady,
    minBalance,
    selectedPayment,
    isReady,
  })
);
