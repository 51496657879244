import React from 'react';

import { Step, StepType } from './Step';
import { StepsWrapper } from './styled';

export type StepsProps = {
  steps: StepType[];
};

export const Steps: React.FC<StepsProps> = ({ steps }) => (
  <StepsWrapper>
    {steps.map(({ ...props }) => (
      <Step {...props} />
    ))}
  </StepsWrapper>
);
