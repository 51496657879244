import { PercentDirections, StatisticFields } from './components';
import { AffStatisticsType } from './types';

export const formatPercent = (percentValue: number) =>
  parseFloat(Number(percentValue).toFixed(2));

export const mapStatisticModel = (
  statisticModel: Record<string, string>,
  prevStatisticModel: Record<string, string>
) =>
  Object.values(StatisticFields).reduce<AffStatisticsType>((acc, field) => {
    const value = statisticModel[field] ? parseFloat(statisticModel[field]) : 0;
    const prevValue = prevStatisticModel[field]
      ? parseFloat(prevStatisticModel[field])
      : 0;
    const percentValue =
      value > prevValue
        ? ((value - prevValue) / prevValue) * 100
        : ((prevValue - value) / prevValue) * 100;

    return {
      ...acc,
      [field]: {
        value: value ?? 0,
        percent: {
          value: formatPercent(percentValue || 0),
          direction:
            value < prevValue
              ? PercentDirections.minus
              : PercentDirections.plus,
        },
      },
    };
  }, {} as AffStatisticsType);
