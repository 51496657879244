import { takeLatest, takeEvery } from 'redux-saga/effects';

import { paymentEditorActions } from '@ams-package/payment-method-editor';

import { paymentRequestActions } from '../store';

import { handleAddPaymentMethod, handleStartInit, handleSubmit } from './saga';

export function* watchers() {
  yield takeLatest(paymentRequestActions.init, handleStartInit);
  yield takeEvery(paymentRequestActions.submit, handleSubmit);
  yield takeEvery(paymentEditorActions.onSuccess, handleAddPaymentMethod);
}
