import React from 'react';
import styled from 'styled-components';

import Badge from '@xcritical/badge';

import Panel from '@ams-package/panel';

const RegularRequestStubWrapper = styled(Panel)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const RegularRequestStub = () => (
  <RegularRequestStubWrapper>
    <ContentWrapper>
      <h3>Regular Payouts</h3>
      <Badge baseAppearance="default" appearance="info">
        SOON
      </Badge>
    </ContentWrapper>
  </RegularRequestStubWrapper>
);
