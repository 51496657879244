import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Form, { FormField } from '@xcritical/forms';

import { useRegistry } from '@crm-framework/registry';

import { Label } from '@ams-package/label';
import {
  PasswordFormField,
  PasswordValidationFormField,
} from '@ams-package/input';
import { Button } from '@ams-package/button';

import {
  CHANGE_PASSWORD_FORM,
  CHANGE_PASSWORD_FORM_FIELDS,
  CHANGE_PASSWORD_IS_LOADING,
} from '../consts';
import { accountSettingsActions } from '../store';

import { SubmitButtonWrapper, SecurityTabWrapper } from './styled';

export const SecurityTab: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useRegistry<boolean>(CHANGE_PASSWORD_IS_LOADING, false);

  const onChangePassword = useCallback(() => {
    dispatch(accountSettingsActions.changePassword());
  }, []);

  return (
    <Form name={CHANGE_PASSWORD_FORM}>
      <SecurityTabWrapper>
        <h3>Change current password</h3>

        <div>
          <Label isRequired htmlFor={CHANGE_PASSWORD_FORM_FIELDS.oldPassword}>
            Current Password
          </Label>
          <FormField
            id={CHANGE_PASSWORD_FORM_FIELDS.oldPassword}
            name={CHANGE_PASSWORD_FORM_FIELDS.oldPassword}
            shouldFitContainer
            disabled={isLoading}
            component={PasswordFormField}
            placeholder="Enter password"
          />
        </div>
        <div>
          <Label isRequired htmlFor={CHANGE_PASSWORD_FORM_FIELDS.newPassword}>
            New Password
          </Label>
          <FormField
            id={CHANGE_PASSWORD_FORM_FIELDS.newPassword}
            name={CHANGE_PASSWORD_FORM_FIELDS.newPassword}
            shouldFitContainer
            disabled={isLoading}
            component={PasswordValidationFormField}
            position="right bottom"
            placeholder="Enter password"
          />
        </div>
        <div>
          <Label
            isRequired
            htmlFor={CHANGE_PASSWORD_FORM_FIELDS.confirmPassword}>
            Confirm Password
          </Label>
          <FormField
            id={CHANGE_PASSWORD_FORM_FIELDS.confirmPassword}
            name={CHANGE_PASSWORD_FORM_FIELDS.confirmPassword}
            shouldFitContainer
            disabled={isLoading}
            component={PasswordFormField}
            placeholder="Enter password"
          />
        </div>
        <SubmitButtonWrapper>
          <Button
            shouldFitContent
            onClick={onChangePassword}
            disabled={isLoading}>
            Save
          </Button>
        </SubmitButtonWrapper>
      </SecurityTabWrapper>
    </Form>
  );
};
