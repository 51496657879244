import React from 'react';
import { ArrowRight } from 'iconsax-react';

import { PaymentsInfo } from '../account-settings';
import { PaymentRequest, RegularPayout } from '../../components';

import {
  PayoutsRequestWrapper,
  AdditionalInfoWrapper,
  PaymentMethodLinkWrapper,
} from './styled';

export const PayoutsRequest = () => (
  <>
    <PayoutsRequestWrapper>
      <PaymentRequest />
      <RegularPayout />
    </PayoutsRequestWrapper>
    <AdditionalInfoWrapper>
      <PaymentMethodLinkWrapper to="/account-settings?tabName=paymentSettings">
        <h3>Manage payment methods</h3>
        <ArrowRight />
      </PaymentMethodLinkWrapper>
      <AdditionalInfoWrapper>
        <PaymentsInfo />
      </AdditionalInfoWrapper>
    </AdditionalInfoWrapper>
  </>
);
