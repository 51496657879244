import { PathNames } from '@ams-package/router';

import { StepType } from './components';

export const steps: StepType[] = [
  {
    id: 1,
    title: 'Choose Offer',
    description:
      'To set up an advertising campaign, you must first select an offer',
    linkText: 'Select offer',
    linkUrl: PathNames.offers,
  },
  {
    id: 2,
    title: 'Get a Promo',
    description:
      'After the offer is selected, you need to select a landing page',
  },
  {
    id: 3,
    title: 'Get a Link',
    description:
      'In accordance with the selected landing page, you need to select and copy the link',
  },
  {
    id: 4,
    title: 'Take Money',
    description:
      'As a result of the implemented advertising campaign, you will be able to withdraw funds',
    linkText: 'Go to Payouts',
    linkUrl: PathNames.payouts,
  },
];

export const STORE_NAME = '@main-dashboard';
