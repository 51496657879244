import React from 'react';
import { useSelector } from 'react-redux';

import { getManagerContacts } from '@ams-package/app';
import { CopyIconButton, SocialMediaButtons } from '@ams-package/button';
import { defaultImages } from '@ams-package/icons';

import {
  AccountInfo,
  AccountInfoWrapper,
  AccountInfoAvatar,
  AccountInfoDescription,
  AccountInfoEmail,
  EmailWrapper,
} from './styled';

export const SidebarAccountInfo: React.FC = () => {
  const manager = useSelector(getManagerContacts);

  if (!manager) return <></>;

  return (
    <AccountInfo>
      <AccountInfoWrapper>
        <AccountInfoAvatar src={defaultImages.managerAvatar} alt="Avatar" />
        <AccountInfoDescription>
          <h3>{`${manager.firstname} ${manager.lastname}`}</h3>
          {manager.email && (
            <EmailWrapper>
              <AccountInfoEmail>{manager.email}</AccountInfoEmail>
              <CopyIconButton
                message="Email copied successfully"
                text={manager.email}
                appearance="link"
                color="var(--base-white)"
              />
            </EmailWrapper>
          )}
        </AccountInfoDescription>
      </AccountInfoWrapper>
      <SocialMediaButtons appearance="secondary" />
    </AccountInfo>
  );
};
