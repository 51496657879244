import React from 'react';

import { Dashboard } from '@ams-package/dashboard';
import { useQueryParam } from '@ams-package/utils';

import { PageWrapper } from './styled';
import { settings } from './columns';
import { getStatistic } from './api';

export const Statistics: React.FC = () => {
  const offerName = useQueryParam('campaign');

  return (
    <PageWrapper>
      <h1>
        Statistics
        {offerName && ` by offer ${offerName}`}
      </h1>
      <Dashboard
        api={{
          apiGetGridData: getStatistic,
        }}
        settings={settings}
      />
    </PageWrapper>
  );
};
