import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { MarkdownWrapper } from './styled';

export const MarkdownContent: React.FC<{ data?: string }> = ({ data }) => {
  if (!data) return <></>;

  return (
    <MarkdownWrapper>
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        unwrapDisallowed>
        {data}
      </Markdown>
    </MarkdownWrapper>
  );
};
